export const tooLong = (string, length) => 
  string && string.length > length

export const isEmpty = string =>
  string.length === 0

export const notAllLetters = string =>
  !/^[a-zA-Z]+$/.test(string)

export const notANumber = string =>
  string.length === 0 || isNaN(string)

export const isNotUpper = string =>
  string.split('').filter(character =>
    !notANumber(character) || 
      character !== character.toUpperCase()).length !== 0

export const notValidEmail = string =>
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(string)