import React, { useEffect, useRef, useCallback, useState } from 'react'

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import '../css/Portfolio.css'

import Project from './Project'

const Portfolio = ({ locoScroll, setNavbar2Refs }) => {
  const projectsRefs = useRef([])

  const portfolioContainerRef = useRef(null)
  const viewportHeightCatcherRef = useRef(null)
  const portfolioWrapperRef = useRef(null)
  
  const portfolioWrapperScrollTriggerRef = useRef(null)

  const [scrollTriggerCreated, setScrollTriggerCreated] = useState(false)

  const addToRefsArray = (refsArray, element) => {
    if (element && !refsArray.current.includes(element))
      refsArray.current.push(element)
  }

  const setLayoutVariables = useCallback(() => {
    const viewportHeight = viewportHeightCatcherRef.current.getBoundingClientRect().height

    if (window.visualViewport.width > 700) {
      portfolioContainerRef.current.style.height = `${(viewportHeight - 80) * 5}px`
      portfolioWrapperRef.current.style.height = `${viewportHeight - 80}px`
    } else {
      portfolioContainerRef.current.style.height = `${viewportHeight * 5}px`
      portfolioWrapperRef.current.style.height = `${viewportHeight - 80}px`
    }
  }, [])

  const onResize = useCallback(() => {
    if (window.visualViewport.width > 700) {
      setLayoutVariables()

      if (portfolioWrapperScrollTriggerRef.current) 
        portfolioWrapperScrollTriggerRef.current.kill(true)

      const portfolioMasterTimeline = gsap.timeline()
      const portfolioWrapperPin = gsap.timeline()
      const slidePanelsAnimation = gsap.timeline()

      const height = portfolioContainerRef.current.getBoundingClientRect().height

      portfolioWrapperPin
        .to(portfolioWrapperRef.current, { y: (height - (window.visualViewport.height - 80)), duration: 4, ease: 'none' })   

      slidePanelsAnimation
        .fromTo(projectsRefs.current[1], { xPercent: 100 }, { xPercent: 0, duration: 1, ease: 'none' })
        .fromTo(projectsRefs.current[2], { xPercent: -100 }, { xPercent: 0, duration: 1, ease: 'none' })
        .fromTo(projectsRefs.current[3], { yPercent: -100 }, { yPercent: 0, duration: 1, ease: 'none' })
        .fromTo(projectsRefs.current[4], { yPercent: 100 }, { yPercent: 0, duration: 1, ease: 'none' })

      portfolioMasterTimeline.add(portfolioWrapperPin)
      portfolioMasterTimeline.add(slidePanelsAnimation)
      
      portfolioWrapperPin.startTime(0)
      slidePanelsAnimation.startTime(0)

      portfolioWrapperScrollTriggerRef.current = ScrollTrigger.create({
        animation: portfolioMasterTimeline,
        trigger: portfolioWrapperRef.current,
        start: `top 40px`,
        end: `+=${height - (window.visualViewport.height - 80)}`,
        scrub: true
      })

      setScrollTriggerCreated(false)
    } else if (!scrollTriggerCreated) {
      setLayoutVariables(true)

      if (portfolioWrapperScrollTriggerRef.current) 
      portfolioWrapperScrollTriggerRef.current.kill(true)
      
      gsap.set([portfolioWrapperRef.current, projectsRefs.current], { clearProps: "all" })
      
      setScrollTriggerCreated(true)
    }
  }, [setLayoutVariables, scrollTriggerCreated])

  useEffect(() => {
    if (locoScroll)
      onResize()
    
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [locoScroll, onResize])

  useEffect(() => {
    const portfolioWrapper = portfolioWrapperRef.current

    return () => {
      const animations = ScrollTrigger.getAll().filter(
        scrollTrigger => scrollTrigger.vars.trigger === portfolioWrapper)
  
      if (animations.length)
        animations.map(animation => animation.kill())
    }
  }, [])

  useEffect(() => {
    setNavbar2Refs([portfolioContainerRef.current])
  }, [setNavbar2Refs, portfolioContainerRef])

  useEffect(() => {
    return () => {
      if (locoScroll?.scroll) {
        locoScroll.scrollTo(0, 0, 0, [0.05, 0.05, 0.05, 0.05], true, () => ScrollTrigger.refresh(true))
      } else {
        window.scrollTo(0, 0)
      }
    }
  }, [locoScroll])

  return (
    <div className="portfolio-container" ref={portfolioContainerRef}>
      <section className="viewport-height-catcher" ref={viewportHeightCatcherRef}></section>
      <div className="portfolio-wrapper" ref={portfolioWrapperRef}>
        <Project locoScroll={locoScroll} projectName={'blissful'} category={'E-commerce Site'} liveLink={'https://jaak-kivinukk-blissful.herokuapp.com/massages'} githubLink={'https://github.com/Jaakal/blissful-public'} toolCount={7} panelIndex={0} triggerRef={portfolioWrapperRef.current} ref={(element) => addToRefsArray(projectsRefs, element)} />
        <Project locoScroll={locoScroll} projectName={'no-time-to-die'} category={'Canvas Animation'} liveLink={'https://jaakal.github.io/no-time-to-die/'} githubLink={'https://github.com/Jaakal/no-time-to-die'} toolCount={4} panelIndex={1} triggerRef={portfolioWrapperRef.current} ref={(element) => addToRefsArray(projectsRefs, element)} />
        <Project locoScroll={locoScroll} projectName={'micro-wars'} category={'Space Shooter Game'} liveLink={'https://jaakal.github.io/micro-wars/'} githubLink={'https://github.com/Jaakal/micro-wars'} toolCount={5} panelIndex={2} triggerRef={portfolioWrapperRef.current} ref={(element) => addToRefsArray(projectsRefs, element)} />
        <Project locoScroll={locoScroll} projectName={'nasa-apod'} category={`App connected with NASA API's`} liveLink={'https://jaakal.github.io/nasa-apod/'} githubLink={'https://github.com/Jaakal/nasa-apod'} toolCount={5} panelIndex={3} triggerRef={portfolioWrapperRef.current} ref={(element) => addToRefsArray(projectsRefs, element)} />
        <Project locoScroll={locoScroll} projectName={'weathery'} category={'Weather app connected with OpenWeather API'} liveLink={'https://jaakal.github.io/weather-app/'} githubLink={'https://github.com/Jaakal/weather-app'} toolCount={4} panelIndex={4} triggerRef={portfolioWrapperRef.current} ref={(element) => addToRefsArray(projectsRefs, element)} />
      </div>
    </div>
  )
}

export default Portfolio
