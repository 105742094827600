import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { withResizeDetector } from 'react-resize-detector';

import { gsap } from 'gsap';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';

import '../css/Navigation.css'

gsap.registerPlugin(DrawSVGPlugin);

const addToRefsArray = (refsArray, element) => {
  if (element && !refsArray.current.includes(element))
    refsArray.current.push(element)
}

const Navigation = ({ width, height, targetRef, locoScroll, transition, setComponent, setNavbar2Refs, navbar2Refs }) => {
  const location = useLocation()

  const hamburgerIconRefs = useRef([])
  const navbar1Ref = useRef(null)
  const navbar2Ref = useRef(null)
  const smallScreenBackgroundWrapperRef = useRef(null)
  const smallScreenWordMenuRef = useRef(null)
  const transition1Ref = useRef(null)

  const [hamburgerButtonVisible, setHamburgerButtonVisible] = useState(false)
  const [hoverOnHamburgerButton, setHoverOnHamburgerButton] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)

  const [navbar2, setNavbar2] = useState([])

  const { active, shrinking } = transition

  const onResize = useCallback(() => {
    if (window.visualViewport.width > 700 && hamburgerButtonVisible) {
      setHamburgerButtonVisible(false)
    } else if (window.visualViewport.width <= 700 && !hamburgerButtonVisible && !active) {
      setHamburgerButtonVisible(true)
    }
  }, [hamburgerButtonVisible, setHamburgerButtonVisible, active])

  useEffect(() => onResize(), [width, height, onResize])

  useEffect(() => {
    if (hamburgerButtonVisible) {
      if (hoverOnHamburgerButton) {
        if (!menuOpen) {
          gsap.timeline()
            .to(hamburgerIconRefs.current[0], { drawSVG: '33% 0%' })
            .to(hamburgerIconRefs.current[1], { drawSVG: '12% 100%', opacity: 1 }, '<')
            .to(hamburgerIconRefs.current[2], { drawSVG: '23% 0%' }, '<')
        } else {
          gsap.timeline()
            .to(hamburgerIconRefs.current[0], { strokeOpacity: 0.7 })
            .to(hamburgerIconRefs.current[1], { strokeOpacity: 0.7 }, '<')
            .to(hamburgerIconRefs.current[2], { strokeOpacity: 0.7 }, '<')
        }
      } else {
        if (!menuOpen) {
          gsap.timeline()
            .to(hamburgerIconRefs.current[0], { drawSVG: '22% 0%' })
            .to(hamburgerIconRefs.current[1], { drawSVG: '30% 100%', opacity: 1 }, '<')
            .to(hamburgerIconRefs.current[2], { drawSVG: '18% 0%' }, '<')
        } else {
          gsap.timeline()
            .to(hamburgerIconRefs.current[0], { strokeOpacity: 1 })
            .to(hamburgerIconRefs.current[1], { strokeOpacity: 1 }, '<')
            .to(hamburgerIconRefs.current[2], { strokeOpacity: 1 }, '<')
        }
      }
    }
  }, [hamburgerButtonVisible, hoverOnHamburgerButton, menuOpen])

  useEffect(() => {
    if (hamburgerButtonVisible) {
      gsap.set(hamburgerIconRefs.current[0], { drawSVG: '22% 0%', autoAlpha: 1 })
      gsap.set(hamburgerIconRefs.current[1], { drawSVG: '30% 100%', autoAlpha: 1, opacity: 1 })
      gsap.set(hamburgerIconRefs.current[2], { drawSVG: '18% 0%', autoAlpha: 1 })

      gsap.to(hamburgerIconRefs.current[3], { autoAlpha: 1 })
      
    } else {
      setMenuOpen(false)

      gsap.to([hamburgerIconRefs.current[3], smallScreenWordMenuRef.current], { autoAlpha: 0 })
      gsap.set(smallScreenBackgroundWrapperRef.current, { bottom: 0 })
    }
  }, [hamburgerButtonVisible])

  useEffect(() => {
    if (active && hamburgerButtonVisible) {
      gsap.to(hamburgerIconRefs.current[3], { autoAlpha: 0, duration: 1 })
    } else if (!active && !shrinking && window.visualViewport.width <= 700) {
      gsap.to(hamburgerIconRefs.current[3], { autoAlpha: 1 })
    }
  }, [active, shrinking, hamburgerButtonVisible])

  useEffect(() => {
    if (active && !shrinking) {
      gsap.to([navbar1Ref.current, navbar2Ref.current], { opacity: 0, duration: 0.4 })

      if (navbar2Refs.length)
        setNavbar2Refs([])
      
      if (navbar2.length)
        setNavbar2([])
    } else if (!active && !shrinking) {
      switch (location.pathname) {
        case '/':
          if (navbar2Refs.length) {
            setNavbar2(['Photo', 'Info'])
          } else if (navbar2.length) {
            setNavbar2([])
          }
          break
        case '/portfolio':
          if (navbar2Refs.length)
            setNavbar2(['Project 1', 'Project 2', 'project 3', 'project 4', 'project 5'])
          break
        case '/about':
          if (navbar2Refs.length)
            setNavbar2(['Who am I', 'Hobbies', 'Forward'])
          break
        case '/contact':
          if (navbar2Refs.length)
            setNavbar2(['Greeting', 'Say Hello', 'Location', 'Contacts'])
          break
        default:
          setNavbar2([])
          break
      }

      gsap.to([navbar1Ref.current, navbar2Ref.current], { opacity: 1, duration: 0.4 })
    }
  }, [navbar2Refs, active, shrinking, location.pathname, navbar2.length, setNavbar2Refs])

  useEffect(() => {
    const tempNavbar2Ref = navbar2Ref.current

    if (location.pathname === '/portfolio' || location.pathname === '/about' || location.pathname === '/contact')
      tempNavbar2Ref.classList.add(location.pathname.substring(1))

    return () => { 
      const classToRemove = location.pathname.substring(1) || 'home'
      tempNavbar2Ref.classList.remove(classToRemove)
    }
  }, [location.pathname])

  const onNavbar2Click = index => {
    if (locoScroll) {
      let element
      let offset

      if (location.pathname === '/portfolio' || location.pathname === '/about') {        
        element = index * (window.innerHeight - (window.innerWidth < 700 ? 0 : 80))
        offset = 0
      } else {
        element = navbar2Refs[index]
        offset = (window.innerWidth < 700 ? 0 : -40)
      }

      if (locoScroll?.scroll)
        locoScroll.scrollTo(element, offset, 500, [0.25, 0.00, 0.35, 1.00], false)
    }
  }

  const closeMenu = () => {
    setMenuOpen(false)

    gsap.timeline()
      .to(hamburgerIconRefs.current[0], { drawSVG: '33% 0%', strokeOpacity: 1 })
      .to(hamburgerIconRefs.current[1], { drawSVG: '12% 100%', strokeOpacity: 1, opacity: 1 }, '<')
      .to(hamburgerIconRefs.current[2], { drawSVG: '23% 0%', strokeOpacity: 1 }, '<')
      .to(smallScreenWordMenuRef.current, { autoAlpha: 0, duration: 0.3 }, '<')
      
    if (parseInt(smallScreenBackgroundWrapperRef.current.getBoundingClientRect().y, 10) === parseInt(- (window.innerHeight + transition1Ref.current.getBoundingClientRect().height), 10)) {
      gsap.to(smallScreenBackgroundWrapperRef.current, { bottom: (-2) * (window.innerHeight + transition1Ref.current.getBoundingClientRect().height), duration: 0.4, onComplete: () => gsap.set(smallScreenBackgroundWrapperRef.current, { bottom: 0 }) })
    } else {
      gsap.to(smallScreenBackgroundWrapperRef.current, { bottom: 0, duration: 0.4 })
    }
  }

  const onHamburgerButtonClick = () => {
    if (menuOpen) {
      closeMenu()
    } else {
      setMenuOpen(true)

      gsap.timeline()
        .to(hamburgerIconRefs.current[0], { drawSVG: '100% 62%' })
        .to(hamburgerIconRefs.current[1], { drawSVG: '46% 46%', opacity: 0 }, '<')
        .to(hamburgerIconRefs.current[2], { drawSVG: '100% 62%' }, '<')

      gsap.timeline()
        .to(smallScreenBackgroundWrapperRef.current, { bottom: - (window.innerHeight + transition1Ref.current.getBoundingClientRect().height), duration: 0.4, onStart: () => gsap.to(smallScreenWordMenuRef.current, { autoAlpha: 1, duration: 0.3 }) })
    }
  }

  const onSmallScreenNavbarClick = component => {
    closeMenu()
    setComponent({ path: component })
  } 

  return (
    <>
      <div className={`hamburger-icon${hamburgerButtonVisible ? '' : ' hidden'}`} ref={(element) => addToRefsArray(hamburgerIconRefs, element)} onMouseEnter={() => setHoverOnHamburgerButton(true)} onMouseLeave={() => setHoverOnHamburgerButton(false)} onClick={onHamburgerButtonClick}>
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 380 220.17">
          <path className="hamburger-icon-stroke" ref={(element) => addToRefsArray(hamburgerIconRefs, element)} d="M370,70H110s-59.82-3.72-80,40c-6.74,14.6,9,99.74,25,100,23.86.39,25,0,25,0L300,10"/>
          <line className="hamburger-icon-stroke" ref={(element) => addToRefsArray(hamburgerIconRefs, element)} x1="10" y1="110" x2="370" y2="110"/>
          <path className="hamburger-icon-stroke" ref={(element) => addToRefsArray(hamburgerIconRefs, element)} d="M370,150H110s-47.37,3.12-80-40C19.2,95.73,41.58,10,55,10H80L300,210"/>
        </svg>
      </div>
      <div className={`small-screen-navbar-wrapper${menuOpen ? '' : ' hidden'}`}>
        <div className="small-screen-navbar-background-wrapper" ref={smallScreenBackgroundWrapperRef}>
          <div className="background1"></div>
          <div className="transition1" ref={transition1Ref}></div>
          <div className="background2"></div>
          <div className="transition2"></div>
          <div className="background3"></div>
        </div>
        <div className="word-menu" ref={smallScreenWordMenuRef}>
          <div className="link" onClick={() => onSmallScreenNavbarClick('/')}>Home<span className="border-bottom"></span></div>
          <div className="link" onClick={() => onSmallScreenNavbarClick('/portfolio')}>Portfolio<span className="border-bottom"></span></div>
          <div className="link" onClick={() => onSmallScreenNavbarClick('/about')}>About<span className="border-bottom"></span></div>
          <div className="link" onClick={() => onSmallScreenNavbarClick('/contact')}>Contact<span className="border-bottom"></span></div>
          <div className="social-media-links-wrapper">
            <a className="linked-in-icon" href="https://www.linkedin.com/in/jaak-kivinukk/" target="_blank" rel="noopener noreferrer">
              <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 189.9 189.57">
                <g id="linked-in">
                  <path d="M67.36,189.39c.94,0,1.75.12,2.57.12,11.25,0,22.5-.06,33.75.05,2.22,0,2.8-.61,2.79-2.8q-.12-29,0-58c0-3.49.17-7,.44-10.48a42.14,42.14,0,0,1,1-6.41c2.93-12.51,11.55-18.56,24.39-17.4,8.25.75,13.8,4.93,16,12.86a55.4,55.4,0,0,1,2,13.78c.22,21.67.14,43.35.17,65v3.29c1.2.05,2,.12,2.83.12,11.25,0,22.5-.07,33.75.06,2.38,0,2.91-.75,2.9-3-.07-22.34,0-44.68-.07-67-.05-10.43-.57-20.83-3.55-30.94C181.94,74,172.67,64.32,157.46,61.28a75.37,75.37,0,0,0-16.14-1.41C126.38,60.2,114.23,66.1,106,79a8.11,8.11,0,0,1-1.11,1V63.06H67.36Z"/>
                  <path d="M3.19,63c-.06.9-.13,1.55-.13,2.21Q3,126.1,3,187c0,1.92.5,2.6,2.52,2.59q17.24-.13,34.48,0c2,0,2.51-.7,2.51-2.6q-.06-60.86,0-121.71c0-1.84-.5-2.44-2.39-2.43-11.66.07-23.32,0-35,0C4.59,62.86,4,63,3.19,63Z"/>
                  <path d="M22.68,45.65A22.83,22.83,0,1,0,0,22.63,22.83,22.83,0,0,0,22.68,45.65Z"/>
                </g>
              </svg>
            </a>
            <a className="github-icon" href="https://github.com/Jaakal" target="_blank" rel="noopener noreferrer">
              <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 208.74 250.85">
                <path id="github" d="M143.1,166.72c1.74,4.67,3.65,8.92,4.88,13.36a50,50,0,0,1,2.1,12.5c.24,18.49.1,37,.17,55.48,0,1.79-.53,2.67-2.37,2.63-1,0-2,.1-3,.1q-28,0-56,.06c-.66,0-1.33,0-2,0-2.65-.19-3.64-1.06-3.67-3.71-.07-6.33,0-12.67,0-19V217.61c-1.33.12-2.38.17-3.4.32-9.65,1.41-19.27,1.78-28.68-1.32A40.13,40.13,0,0,1,31.55,203c-3.43-4.45-6.11-9.48-9.22-14.19C17.94,182.1,13.57,175.42,9,168.9c-1.21-1.7-3.29-2.77-4.85-4.24C2.83,163.44,1.71,162,0,160.16c2.26-.67,3.78-1.46,5.33-1.51,8.35-.29,15.59,2.52,21.22,8.72,3.57,3.93,6.74,8.23,10,12.43C42.21,187,49,192.52,58.16,194.57a39.83,39.83,0,0,0,21.9-1.52,5.57,5.57,0,0,0,3-3.81c.85-7.2,2.36-14.11,6.4-20.26a3.82,3.82,0,0,0,.25-1.14c-5.33-1.54-10.58-3.05-15.82-4.6-12.62-3.73-24.19-9.37-33.45-19a54.41,54.41,0,0,1-13.93-26.08c-3.43-14.93-2.75-30-.64-44.95,1.22-8.74,3.51-17.31,8.85-24.67A37,37,0,0,1,38,44.48a6.4,6.4,0,0,0,1.54-6.71,54.93,54.93,0,0,1-2.11-23.19,80.34,80.34,0,0,1,2.4-11.45C40.64.34,42.26-.4,45.05.41c8.4,2.45,15.2,7.4,21,13.76,2.48,2.7,4.78,5.56,7,8.43C74,23.7,74.72,24,76,23.24c9.09-5,19-6.48,29.17-6.65s20.48.2,30.71.64a46.2,46.2,0,0,1,20,5.58c1.68.9,2.73.74,4-.72,4.05-4.53,8.11-9.06,12.45-13.3A36,36,0,0,1,187.08.51C191-.71,192.6.19,193.79,4.06c3.42,11.2,4.11,22.4-.28,33.51a5.78,5.78,0,0,0,1.3,6.63c4.94,5.35,8.72,11.42,10.33,18.54A158.24,158.24,0,0,1,208.71,94a89.78,89.78,0,0,1-3.13,27.43c-3.83,13.18-11.69,23.39-22.9,31.11a82.09,82.09,0,0,1-30.82,13C148.94,166.05,146,166.32,143.1,166.72Z"/>
              </svg>
            </a>
            <a className="medium-icon" href="https://medium.com/@jaak.kivinukk" target="_blank" rel="noopener noreferrer">
              <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 241.06 191.68">
                <path id="m" d="M166.48,56c-1.22,3-2.48,5.91-3.65,8.89q-17.75,45.15-35.47,90.29-6.51,16.6-13,33.19c-.6,1.53-1.39,2.61-3.28,2.29a7.31,7.31,0,0,0-2.24,0c-2.06.3-3.11-.62-4-2.48q-10.59-23.41-21.34-46.74Q64.34,99.62,45.16,57.79A4.14,4.14,0,0,0,43.59,56c-.07.84-.2,1.69-.2,2.53q0,44.38,0,88.77a12.22,12.22,0,0,0,2.85,8.05Q58.25,170,70.3,184.65a7.63,7.63,0,0,1,1.57,2.76c.6,2.37-.05,3.25-2.26,3.25q-33.87,0-67.75,0a9.84,9.84,0,0,1-1.11-.14,5.92,5.92,0,0,1,1.16-5.69c7.94-9.43,15.69-19,23.59-28.48A15.33,15.33,0,0,0,29,145.76c-.14-15.5-.08-31-.08-46.51,0-19.42-.06-38.85,0-58.27a14.87,14.87,0,0,0-3.81-10.43c-6.93-8-13.6-16.2-20.32-24.36A5.83,5.83,0,0,1,3.56,3.58C3.14.9,3.5.55,6.23.55,27.65.55,49.06.58,70.48.5a3.54,3.54,0,0,1,3.77,2.39q24.09,53,48.26,106c1.33,2.9,2.69,5.78,4,8.67l.71-.07c1.74-4.28,3.49-8.54,5.21-12.83Q145.22,72.84,158,41c5.13-12.81,10.32-25.6,15.41-38.43.53-1.35,1.16-2,2.66-2l63.26,0a7.2,7.2,0,0,1,1,.16,5.32,5.32,0,0,1-1.84,5.93c-5.37,4.87-10.43,10.07-15.76,15a10.39,10.39,0,0,0-3.41,8.4q.12,42.13.05,84.27c0,15.59.09,31.18-.07,46.76a11.84,11.84,0,0,0,4,9.67c5.11,4.66,9.93,9.63,14.86,14.48a11.57,11.57,0,0,1,1.65,1.86c1.4,2.18.46,4-2.08,4.1-.42,0-.83,0-1.25,0q-43.38,0-86.75-.06c-1.23,0-3.08-.43-3.55-1.27-.86-1.53.09-3.1,1.43-4.38,5.6-5.34,11.11-10.79,16.71-16.14a9.25,9.25,0,0,0,3-7.1q0-52.26,0-104.53a13.56,13.56,0,0,0-.13-1.43Z"/>
              </svg>
            </a>
            <a className="twitter-icon" href="https://twitter.com/JKivinukk" target="_blank" rel="noopener noreferrer">
              <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 222.86 182.31">
                <path id="twitter" d="M0,161.85c24.49,1.71,46.51-4.18,66.67-19.14-20.83-2.25-34.94-12.55-42.49-32.3,6.72.77,12.9,1.19,19.51-.71C21.18,102.55,9.53,87.52,8,63.82c6.56,3.23,12.77,5.45,20,5.78C8,52.53,3.6,32.41,14.74,8.81,39.8,37.54,70.92,53.65,108.89,56.3c-.18-4.66-.64-9-.46-13.37.82-20.91,18-39.6,38.79-42.42,15.27-2.07,28.45,2.14,39.63,12.76a3.58,3.58,0,0,0,2.77.75A87.92,87.92,0,0,0,215,4.39c.49-.29,1-.5,2.15-1A46.73,46.73,0,0,1,198.47,28l.44.77,24-6.34c-2.5,3.19-4.37,6.11-6.76,8.52-4.5,4.54-9.24,8.84-14,13.11A4.49,4.49,0,0,0,200.4,48a127.07,127.07,0,0,1-12.8,59.69c-17.56,36.57-45.55,61.06-85.22,70.61-35,8.44-68.59,3.74-99.84-14.84-.64-.38-1.26-.8-1.9-1.2Z"/>
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div className="navigation-container" ref={targetRef}>
        <div className="logo">
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 181.99 218.12">
          <g>
            <path d="M177.19.37c-.58,1.1-1,2-1.49,2.81q-26.55,45.51-53.15,91a4.43,4.43,0,0,0-.13,4.82c16.94,33.59,33.76,67.24,50.76,100.79,2.5,4.94,5.8,9.47,8.81,14.33a7.17,7.17,0,0,1-1.44.25c-5.41,0-10.83,0-16.25,0-1.51,0-2-.47-2.38-2a64,64,0,0,0-4.06-11.2Q136,157.42,113.94,113.73c-.32-.64-.74-1.23-1.38-2.29-2.58,5.17-6.24,9-6.17,15.18.31,28.16.14,56.32.14,84.48v3.11H91.94v-3.37q0-91.35-.06-182.71C91.86,18.9,93.49,9.44,89,.24c1.19-.08,2-.17,2.75-.18,4,0,8,.1,12,0,2.13-.08,3,.46,2.86,2.76-.19,2.49,0,5,0,7.5V111.48l.42.24c.48-.68,1-1.34,1.42-2.06Q139.46,56.4,170.54,3.1C172.54-.33,172.56-.34,177.19.37Z"/>
            <path d="M0,199.89l8.53-5c1.35,2.33,2.45,4.46,3.76,6.44,6,9.09,14.47,13.23,25.33,12.29S55.68,207,59.9,197.22a54.22,54.22,0,0,0,4-18.46c.16-2.66.18-5.33.18-8q0-82.86,0-165.72c0-3.49,0-3.49,3.53-3.49,2.84,0,5.67.09,8.5,0C78,1.44,78.93,2,78.67,4a20.84,20.84,0,0,0,0,2.5q0,83.37,0,166.73c0,7.75-.89,15.29-4.26,22.36A36.34,36.34,0,0,1,52.2,215.22c-11.23,3.7-22.56,4-33.82,0A35.06,35.06,0,0,1,0,199.89Z"/>
          </g>
        </svg>
        </div>
        <div className="navbar1" ref={navbar1Ref}>
          <div className="word-menu">
            <div className="link" onClick={() => setComponent({ path: '/' })}>Home</div>
            <div className="link" onClick={() => setComponent({ path: '/portfolio' })}>Portfolio</div>
            <div className="link" onClick={() => setComponent({ path: '/about' })}>About</div>
            <div className="link" onClick={() => setComponent({ path: '/contact' })}>Contact</div>
          </div>
        </div>
        <div className="navbar2" ref={navbar2Ref}>
          <div className="word-menu">
            {navbar2.map((element, index) => {
              return <div className={'link'} onClick={() => onNavbar2Click(index)} key={index}>{element.split('').map((letter, index) => <div className="letter" key={index}>{letter}</div>)}</div>
            })}
          </div>
        </div>
        <div className="social-media-links-wrapper">
          <a className="linked-in-icon" href="https://www.linkedin.com/in/jaak-kivinukk/" target="_blank" rel="noopener noreferrer">
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 189.9 189.57">
              <g id="linked-in">
                <path d="M67.36,189.39c.94,0,1.75.12,2.57.12,11.25,0,22.5-.06,33.75.05,2.22,0,2.8-.61,2.79-2.8q-.12-29,0-58c0-3.49.17-7,.44-10.48a42.14,42.14,0,0,1,1-6.41c2.93-12.51,11.55-18.56,24.39-17.4,8.25.75,13.8,4.93,16,12.86a55.4,55.4,0,0,1,2,13.78c.22,21.67.14,43.35.17,65v3.29c1.2.05,2,.12,2.83.12,11.25,0,22.5-.07,33.75.06,2.38,0,2.91-.75,2.9-3-.07-22.34,0-44.68-.07-67-.05-10.43-.57-20.83-3.55-30.94C181.94,74,172.67,64.32,157.46,61.28a75.37,75.37,0,0,0-16.14-1.41C126.38,60.2,114.23,66.1,106,79a8.11,8.11,0,0,1-1.11,1V63.06H67.36Z"/>
                <path d="M3.19,63c-.06.9-.13,1.55-.13,2.21Q3,126.1,3,187c0,1.92.5,2.6,2.52,2.59q17.24-.13,34.48,0c2,0,2.51-.7,2.51-2.6q-.06-60.86,0-121.71c0-1.84-.5-2.44-2.39-2.43-11.66.07-23.32,0-35,0C4.59,62.86,4,63,3.19,63Z"/>
                <path d="M22.68,45.65A22.83,22.83,0,1,0,0,22.63,22.83,22.83,0,0,0,22.68,45.65Z"/>
              </g>
            </svg>
          </a>
          <a className="github-icon" href="https://github.com/Jaakal" target="_blank" rel="noopener noreferrer">
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 208.74 250.85">
              <path id="github" d="M143.1,166.72c1.74,4.67,3.65,8.92,4.88,13.36a50,50,0,0,1,2.1,12.5c.24,18.49.1,37,.17,55.48,0,1.79-.53,2.67-2.37,2.63-1,0-2,.1-3,.1q-28,0-56,.06c-.66,0-1.33,0-2,0-2.65-.19-3.64-1.06-3.67-3.71-.07-6.33,0-12.67,0-19V217.61c-1.33.12-2.38.17-3.4.32-9.65,1.41-19.27,1.78-28.68-1.32A40.13,40.13,0,0,1,31.55,203c-3.43-4.45-6.11-9.48-9.22-14.19C17.94,182.1,13.57,175.42,9,168.9c-1.21-1.7-3.29-2.77-4.85-4.24C2.83,163.44,1.71,162,0,160.16c2.26-.67,3.78-1.46,5.33-1.51,8.35-.29,15.59,2.52,21.22,8.72,3.57,3.93,6.74,8.23,10,12.43C42.21,187,49,192.52,58.16,194.57a39.83,39.83,0,0,0,21.9-1.52,5.57,5.57,0,0,0,3-3.81c.85-7.2,2.36-14.11,6.4-20.26a3.82,3.82,0,0,0,.25-1.14c-5.33-1.54-10.58-3.05-15.82-4.6-12.62-3.73-24.19-9.37-33.45-19a54.41,54.41,0,0,1-13.93-26.08c-3.43-14.93-2.75-30-.64-44.95,1.22-8.74,3.51-17.31,8.85-24.67A37,37,0,0,1,38,44.48a6.4,6.4,0,0,0,1.54-6.71,54.93,54.93,0,0,1-2.11-23.19,80.34,80.34,0,0,1,2.4-11.45C40.64.34,42.26-.4,45.05.41c8.4,2.45,15.2,7.4,21,13.76,2.48,2.7,4.78,5.56,7,8.43C74,23.7,74.72,24,76,23.24c9.09-5,19-6.48,29.17-6.65s20.48.2,30.71.64a46.2,46.2,0,0,1,20,5.58c1.68.9,2.73.74,4-.72,4.05-4.53,8.11-9.06,12.45-13.3A36,36,0,0,1,187.08.51C191-.71,192.6.19,193.79,4.06c3.42,11.2,4.11,22.4-.28,33.51a5.78,5.78,0,0,0,1.3,6.63c4.94,5.35,8.72,11.42,10.33,18.54A158.24,158.24,0,0,1,208.71,94a89.78,89.78,0,0,1-3.13,27.43c-3.83,13.18-11.69,23.39-22.9,31.11a82.09,82.09,0,0,1-30.82,13C148.94,166.05,146,166.32,143.1,166.72Z"/>
            </svg>
          </a>
          <a className="medium-icon" href="https://medium.com/@jaak.kivinukk" target="_blank" rel="noopener noreferrer">
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 241.06 191.68">
              <path id="m" d="M166.48,56c-1.22,3-2.48,5.91-3.65,8.89q-17.75,45.15-35.47,90.29-6.51,16.6-13,33.19c-.6,1.53-1.39,2.61-3.28,2.29a7.31,7.31,0,0,0-2.24,0c-2.06.3-3.11-.62-4-2.48q-10.59-23.41-21.34-46.74Q64.34,99.62,45.16,57.79A4.14,4.14,0,0,0,43.59,56c-.07.84-.2,1.69-.2,2.53q0,44.38,0,88.77a12.22,12.22,0,0,0,2.85,8.05Q58.25,170,70.3,184.65a7.63,7.63,0,0,1,1.57,2.76c.6,2.37-.05,3.25-2.26,3.25q-33.87,0-67.75,0a9.84,9.84,0,0,1-1.11-.14,5.92,5.92,0,0,1,1.16-5.69c7.94-9.43,15.69-19,23.59-28.48A15.33,15.33,0,0,0,29,145.76c-.14-15.5-.08-31-.08-46.51,0-19.42-.06-38.85,0-58.27a14.87,14.87,0,0,0-3.81-10.43c-6.93-8-13.6-16.2-20.32-24.36A5.83,5.83,0,0,1,3.56,3.58C3.14.9,3.5.55,6.23.55,27.65.55,49.06.58,70.48.5a3.54,3.54,0,0,1,3.77,2.39q24.09,53,48.26,106c1.33,2.9,2.69,5.78,4,8.67l.71-.07c1.74-4.28,3.49-8.54,5.21-12.83Q145.22,72.84,158,41c5.13-12.81,10.32-25.6,15.41-38.43.53-1.35,1.16-2,2.66-2l63.26,0a7.2,7.2,0,0,1,1,.16,5.32,5.32,0,0,1-1.84,5.93c-5.37,4.87-10.43,10.07-15.76,15a10.39,10.39,0,0,0-3.41,8.4q.12,42.13.05,84.27c0,15.59.09,31.18-.07,46.76a11.84,11.84,0,0,0,4,9.67c5.11,4.66,9.93,9.63,14.86,14.48a11.57,11.57,0,0,1,1.65,1.86c1.4,2.18.46,4-2.08,4.1-.42,0-.83,0-1.25,0q-43.38,0-86.75-.06c-1.23,0-3.08-.43-3.55-1.27-.86-1.53.09-3.1,1.43-4.38,5.6-5.34,11.11-10.79,16.71-16.14a9.25,9.25,0,0,0,3-7.1q0-52.26,0-104.53a13.56,13.56,0,0,0-.13-1.43Z"/>
            </svg>
          </a>
          <a className="twitter-icon" href="https://twitter.com/JKivinukk" target="_blank" rel="noopener noreferrer">
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 222.86 182.31">
              <path id="twitter" d="M0,161.85c24.49,1.71,46.51-4.18,66.67-19.14-20.83-2.25-34.94-12.55-42.49-32.3,6.72.77,12.9,1.19,19.51-.71C21.18,102.55,9.53,87.52,8,63.82c6.56,3.23,12.77,5.45,20,5.78C8,52.53,3.6,32.41,14.74,8.81,39.8,37.54,70.92,53.65,108.89,56.3c-.18-4.66-.64-9-.46-13.37.82-20.91,18-39.6,38.79-42.42,15.27-2.07,28.45,2.14,39.63,12.76a3.58,3.58,0,0,0,2.77.75A87.92,87.92,0,0,0,215,4.39c.49-.29,1-.5,2.15-1A46.73,46.73,0,0,1,198.47,28l.44.77,24-6.34c-2.5,3.19-4.37,6.11-6.76,8.52-4.5,4.54-9.24,8.84-14,13.11A4.49,4.49,0,0,0,200.4,48a127.07,127.07,0,0,1-12.8,59.69c-17.56,36.57-45.55,61.06-85.22,70.61-35,8.44-68.59,3.74-99.84-14.84-.64-.38-1.26-.8-1.9-1.2Z"/>
            </svg>
          </a>
        </div>
      </div>
    </>
  )
}

export default withResizeDetector(Navigation)
