import React, { useCallback, useEffect, useRef } from 'react'

import { gsap } from 'gsap';
import { SplitText } from "gsap/SplitText";
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import '../css/WhoAmI.css'

gsap.registerPlugin(SplitText);

const addToRefsArray = (refsArray, element) => {
  if (element && !refsArray.current.includes(element))
    refsArray.current.push(element)
}

const setStylesOnElement = (element, styles) => {
  Object.assign(element.style, styles);
}

const WhoAmI = ({
  locoScroll,
  transition,
  viewportHeightCatcher
}) => {
  const sectionRefs = useRef([])
  const whoAmIScrollerTriggerRef = useRef(null)
  const whoAmIRef = useRef(null)
  const notSmallScreenRef = useRef(true)

  const { active, shrinking } = transition

  const setScrollTrigger = () => {
    const splitTextSectionHeadline = new SplitText(sectionRefs.current[0], { type: "words, chars" })
    const splitTextSectionText = new SplitText(sectionRefs.current[1], { type: "words, chars" })

    const sectionTimeline = gsap.timeline()
      .from(sectionRefs.current[3], { rotate: 1080, z: -400, autoAlpha: 0, duration: 2 })
      .fromTo(sectionRefs.current[2], { xPercent: -50, yPercent: -50, rotate: 45, duration: 1 }, { xPercent: -50, yPercent: -50, rotate: 1485, duration: 2 }, '<')
      .from(sectionRefs.current[4], { rotationY: -450, transformOrigin: '100% 50%', autoAlpha: 0, duration: 1 }, '<+1.5')
      .from(sectionRefs.current[5], { rotationY: -450, transformOrigin: '0% 50%', autoAlpha: 0, duration: 2 }, '<')
      .from(sectionRefs.current[6], { rotationY: -200, transformOrigin: '50% 50% -400', autoAlpha: 0, duration: 2 }, '<+1.5')
      .from(sectionRefs.current[7], { rotationY: -200, transformOrigin: '50% 50% 400', autoAlpha: 0, duration: 2 }, '<')
      .from(sectionRefs.current[8], { z: 800, autoAlpha: 0, duration: 2 }, '<')

      .from(splitTextSectionHeadline.chars, {
        duration: 1, 
        scale: 4,
        autoAlpha: 0, 
        rotationX: -360, 
        transformOrigin:"100% 50%", 
        ease:"back", 
        stagger: 0.02,
      }, '<+1')
        
      .from(splitTextSectionText.chars, {
        duration: 2, 
        scale: 'random(0, 4)', 
        autoAlpha: 0, 
        x: 'random(-2000, 2000)', 
        y: 'random(-2000, 2000)', 
        z: 'random(-8000, 8000)', 
        stagger: 0.02,
      }, "<+0.5")
    
    gsap.set([sectionRefs.current[0], sectionRefs.current[1]], { visibility: 'visible' })

    whoAmIScrollerTriggerRef.current = ScrollTrigger.create({
      animation: sectionTimeline,
      trigger: whoAmIRef.current,
      start: '0% 50%'
    })

    whoAmIScrollerTriggerRef.current.disable()
  }

  const onResize = useCallback(() => {
    const width = viewportHeightCatcher?.getBoundingClientRect().width - (window.visualViewport.width > 700 ? 80 : 0)
    const height = viewportHeightCatcher?.getBoundingClientRect().height - (window.visualViewport.width > 700 ? 80 : 0)

    // // whoAmIRef.current.style.height = `${height}px`
    // whoAmIRef.current.style.height = `${height}px`

    if (width && height && notSmallScreenRef.current) {
      const smallSquareDiagonalPercentageInitial = 0.25
      const smallSquareDiagonalPercentage = width / height >= 4 / 2.5 ? smallSquareDiagonalPercentageInitial : smallSquareDiagonalPercentageInitial * 2.5 / 4
      const horizontalAligningVariable = 0.5
      const verticalAligningVariable = 0.8

      if (width / height >= 4 / 2.5) {
        setStylesOnElement(
          sectionRefs.current[9],
          {
            top: `${height * (1 - smallSquareDiagonalPercentage * 2 - smallSquareDiagonalPercentage / 2) * verticalAligningVariable + smallSquareDiagonalPercentage * height - smallSquareDiagonalPercentage * 2 * height / Math.sqrt(2) / 2}px`,
            left: `${(width - Math.sqrt(Math.pow(3 * smallSquareDiagonalPercentage * height / Math.sqrt(2), 2) + Math.pow(5 * smallSquareDiagonalPercentage * height / Math.sqrt(2), 2))) * horizontalAligningVariable + smallSquareDiagonalPercentage * height - smallSquareDiagonalPercentage * 2 * height / Math.sqrt(2) / 2}px`,
            width: `${3 * smallSquareDiagonalPercentage * height / Math.sqrt(2)}px`,
            height: `${5 * smallSquareDiagonalPercentage * height / Math.sqrt(2)}px`,
            transformOrigin: `33.3333% 20%`,
            transform: 'rotate(-45deg)'
          }
        )
      } else {
        setStylesOnElement(
          sectionRefs.current[9],
          {
            top: `${(height - width * (smallSquareDiagonalPercentage * 2 + smallSquareDiagonalPercentage / 2)) * verticalAligningVariable * 1.1 + smallSquareDiagonalPercentage * width - smallSquareDiagonalPercentage * 2 * width / Math.sqrt(2) / 2}px`,
            left: `${(width - Math.sqrt(Math.pow(3 * smallSquareDiagonalPercentage * width / Math.sqrt(2), 2) + Math.pow(5 * smallSquareDiagonalPercentage * width / Math.sqrt(2), 2))) * horizontalAligningVariable + smallSquareDiagonalPercentage * width - smallSquareDiagonalPercentage * 2 * width / Math.sqrt(2) / 2}px`,
            width: `${3 * smallSquareDiagonalPercentage * width / Math.sqrt(2)}px`,
            height: `${5 * smallSquareDiagonalPercentage * width / Math.sqrt(2)}px`,
            transformOrigin: `33.3333% 20%`,
            transform: 'rotate(-45deg)'
          }
        )
      }

      if (window.visualViewport.width > 700) {
        notSmallScreenRef.current = true
      } else {
        notSmallScreenRef.current = false
      }

      if (!whoAmIScrollerTriggerRef.current)
        setScrollTrigger()
    }
  }, [viewportHeightCatcher])

  useEffect(() => {
    if (viewportHeightCatcher && locoScroll)
      onResize()
    
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [viewportHeightCatcher, onResize, locoScroll])

  useEffect(() => {
    if (!active && !shrinking && whoAmIScrollerTriggerRef.current)
      whoAmIScrollerTriggerRef.current.enable()
  }, [active, shrinking])

  return (
    <section className="about-section who-am-i-section" ref={whoAmIRef}>
      <div className="text-wrapper">
        <div className="filler"></div>
        <div className="headline" ref={(element) => addToRefsArray(sectionRefs, element)}>Who Am I</div>
        <div className="text" ref={(element) => addToRefsArray(sectionRefs, element)}>I am a React and Node.js developer and I really enjoy creating animations. Nicely done animations blow life into websites and whenever I can make things move on the screen, my eyes light up.</div>
        <div className="filler"></div>
      </div>

      <div className="section-background-wrapper" ref={(element) => addToRefsArray(sectionRefs, element)}>
        <div className="square big-square" ref={(element) => addToRefsArray(sectionRefs, element)}>
          <div className="background big-square-background" ref={(element) => addToRefsArray(sectionRefs, element)}></div>
        </div>
        <div className="small-square small-square1" ref={(element) => addToRefsArray(sectionRefs, element)}>
          <div className="background small-square1-background"></div>
        </div>
        <div className="small-square small-square2" ref={(element) => addToRefsArray(sectionRefs, element)}>
          <div className="background small-square2-background"></div>
        </div>
        <div className="small-square small-square3" ref={(element) => addToRefsArray(sectionRefs, element)}>
          <div className="background small-square3-background"></div>
        </div>
        <div className="small-square small-square4" ref={(element) => addToRefsArray(sectionRefs, element)}>
          <div className="background small-square4-background"></div>
        </div>
        <div className="small-square small-square5" ref={(element) => addToRefsArray(sectionRefs, element)}>
          <div className="background small-square5-background"></div>
        </div>
        <div className="anomaly-filler">
        </div>
      </div>
    </section>
  )
}

export default WhoAmI
