import React, { useEffect, useRef } from 'react'

import { gsap } from 'gsap';

import '../css/Alert.css'

const Alert = ({ setAlert, message, type = 'ok' }) => {
  const messageRef = useRef(null)

  useEffect(() => {
    if (message)
      gsap.timeline()
        .fromTo(messageRef.current, { autoAlpha: 0, rotateX: -100 }, { autoAlpha: 1, rotateX: 0, transformOrigin: '50% -500px', duration: 1 })
        .to(messageRef.current, { autoAlpha: 0, rotateX: 100, duration: 1, onComplete: () => setAlert({ message: null, alertType: null }) }, '+=3')
  }, [message, setAlert])

  return (
    <div className="alert-container">
      <div className={`message ${type}`} ref={messageRef}>{message}</div>
    </div>
  )
}

export default Alert
