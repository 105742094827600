import React, { useCallback, useEffect, useRef } from 'react'

import { gsap } from 'gsap';
import { SplitText } from "gsap/SplitText";
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import '../css/About.css'

import WhoAmI from './WhoAmI'
import Hobbies from './Hobbies'
import Forward from './Forward'

gsap.registerPlugin(SplitText);

const About = ({ locoScroll, transition, setNavbar2Refs }) => {
  const aboutContainerRef = useRef(null)
  const viewportHeightCatcherRef = useRef(null)
  const aboutWrapperRef = useRef(null)
  const smallScreenResizeFlagRef = useRef(true)

  const setLayoutVariables = useCallback(() => {
    const viewportHeight = viewportHeightCatcherRef.current.getBoundingClientRect().height

    if (window.visualViewport.width > 700) {
      gsap.set(aboutContainerRef.current, { height: (viewportHeight - 80) * 3 })
      gsap.set(aboutWrapperRef.current, { height: viewportHeight - 80 })
      smallScreenResizeFlagRef.current = true
    } else if (smallScreenResizeFlagRef.current) {
      gsap.set(aboutContainerRef.current, { height: viewportHeight * 3 })
      gsap.set(aboutWrapperRef.current, { height: viewportHeight * 3 })
      smallScreenResizeFlagRef.current = false
    }
  }, [])

  const onResize = useCallback(() => {
    if (window.visualViewport.width > 700) {
      setTimeout(() => {
        if (window.visualViewport.width > 700) {
          setLayoutVariables()

          if (locoScroll?.scroll)
            locoScroll?.update()
  
          const animations = ScrollTrigger.getAll().filter(
            scrollTrigger => scrollTrigger.vars.trigger === aboutWrapperRef.current)
      
          if (animations.length)
            animations.map(animation => animation.kill(true))
      
          const aboutWrapperPin = gsap.timeline()
            .to(aboutWrapperRef.current, { x: - (window.visualViewport.width - (window.visualViewport.width > 700 ? 80 : 0)) * 2, y: (window.visualViewport.height - (window.visualViewport.width > 700 ? 80 : 0)) * 2, ease: 'none' })
        
          ScrollTrigger.create({
            animation: aboutWrapperPin,
            trigger: aboutWrapperRef.current,
            start: `top ${(window.visualViewport.width > 700 ? 40 : 0)}px`,
            end: `+=${(window.visualViewport.height - (window.visualViewport.width > 700 ? 80 : 0)) * 2}`,
            scrub: true
          });
        }
      }, 1000)
    } else {
      setLayoutVariables()

      const animations = ScrollTrigger.getAll().filter(
        scrollTrigger => scrollTrigger.vars.trigger === aboutWrapperRef.current)
      
      if (animations.length)
        animations.map(animation => animation.kill(true))
    }
  }, [setLayoutVariables, locoScroll])

  useEffect(() => {
    return () => {
      if (locoScroll?.scroll) {
        locoScroll.scrollTo(0, 0, 0, [0.05, 0.05, 0.05, 0.05], true, () => ScrollTrigger.refresh(true))
      } else {
        window.scrollTo(0, 0)
      }
    }
  }, [locoScroll])

  useEffect(() => {  
    setLayoutVariables()
    onResize()
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [setLayoutVariables, onResize])

  useEffect(() => setNavbar2Refs([aboutContainerRef.current]), [setNavbar2Refs, aboutContainerRef])

  return (
    <div className="about-container" ref={aboutContainerRef}>
      <section className="viewport-height-catcher" ref={viewportHeightCatcherRef}></section>
      <div className="about-wrapper" ref={aboutWrapperRef}>        
        <WhoAmI locoScroll={locoScroll} transition={transition} viewportHeightCatcher={viewportHeightCatcherRef.current}/>
        <Hobbies locoScroll={locoScroll} triggerRef={aboutContainerRef}/>
        <Forward locoScroll={locoScroll} triggerRef={aboutContainerRef}/>
      </div>
    </div>
  )
}

export default About
