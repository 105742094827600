import React, { useEffect, useCallback, useState, useRef } from 'react'
import { isEmpty, tooLong, notValidEmail } from '../utility/formValidator'

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

import emailjs from 'emailjs-com';

import '../css/Contact.css'

gsap.registerPlugin(ScrollToPlugin);

const addToRefsArray = (refsArray, element) => {
  if (element && !refsArray.current.includes(element))
    refsArray.current.push(element)
}

const Contact = ({
  locoScroll,
  transition,
  setNavbar2Refs,
  setScrollToContactForm,
  setAlert,
  scrollToContactForm,
  formDataInitialState,
}) => {
  const heroSectionRefs = useRef([])
  const viewportHeightCatcherRef = useRef([])
  const contactFormSectionRefs = useRef([])
  const addressSectionRefs = useRef([])
  const contactSectionRefs = useRef([])

  const animationsNotEndedRefs = useRef([true, true, true, true])
  const [scrollTriggersCreated, setScrollTriggersCreated] = useState(false)

  const [formData, setFormData] = useState(formDataInitialState)

  const { name, email, message, nameFocused, emailFocused, messageFocused, nameFocusOn, emailFocusOn, messageFocusOn } = formData
  const { active } = transition
  
  const handleChange = event => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    })
  }

  const handleFocus = event => 
    setFormData({
      ...formData,
      [`${event.target.name}FocusOn`]: true
    });

  const handleBlur = event =>
    setFormData({
      ...formData,
      [`${event.target.name}Focused`]: true,
      [`${event.target.name}FocusOn`]: false
    });
  
  const handleSubmit = event => {
    event.preventDefault()

    emailjs.sendForm('service_fu1072b', 'template_ewl6yce', event.target, 'user_L6eONp8rMErre8ypahSAl')
      .then((result) => {
        console.log(result.text);
        setFormData(formDataInitialState)
        setAlert({
          message: 'Message Sent!',
          alertType: 'ok'
        })
      }, (error) => {
        console.error(error.text);
        setAlert({
          message: 'I am sorry, something went wrong! Use contacts from below!',
          alertType: 'danger'
        })
      });
  }

  const setLayoutVariables = useCallback(() => {
    gsap.set(['.contact-hero-section', '.hero-background-mask', '.contact-form-section', '.address-section', '.contacts-section'], { minHeight: viewportHeightCatcherRef.current.getBoundingClientRect().height - (window.visualViewport.width > 700 ? 80: 0) })
  
    if (locoScroll?.scroll)
      locoScroll.update()
  }, [locoScroll])

  const setScrollTriggers = useCallback(() => {
    const animations = ScrollTrigger.getAll().filter(scrollTrigger =>
      scrollTrigger.vars.trigger === heroSectionRefs.current[2])

    if (animations.length)
      animations.map(animation => animation.kill())
    
    if (locoScroll?.scroll) {
      const heroSectionTimeline = gsap.timeline()
        .to(heroSectionRefs.current[0], { y: 150 })
        .to(heroSectionRefs.current[1], { x: 100 }, '<')
  
      ScrollTrigger.create({
        animation: heroSectionTimeline,
        trigger: heroSectionRefs.current[2],
        start: `-${window.visualViewport.width > 700 ? 40 : 0}px 0%`,
        end: `+=${window.visualViewport.height - (window.visualViewport.width > 700 ? 80 : 0)}`,
        scrub: 1
      })
    }

    if (animationsNotEndedRefs.current[0]) {
      gsap.set([addressSectionRefs.current[0], addressSectionRefs.current[1], addressSectionRefs.current[2]], { clearProps: 'all' })

      const addressSectionTimeline = gsap.timeline()
        .from(addressSectionRefs.current[0], { x: 600, autoAlpha: 0, clearProps: 'x', duration: 1,
          onStart: () => { 
            animationsNotEndedRefs.current[0] = false
            
            gsap.timeline()
              .from(addressSectionRefs.current[1], { x: -600, autoAlpha: 0, clearProps: 'x', duration: 1 }, '+=0.2')
              .from(addressSectionRefs.current[2], { y: 200, autoAlpha: 0, clearProps: 'y', duration: 1 }, '<+0.2')
          }})

      ScrollTrigger.create({
        trigger: heroSectionRefs.current[2],
        start: `top -${heroSectionRefs.current[2].getBoundingClientRect().height + contactFormSectionRefs.current[5].getBoundingClientRect().height - addressSectionRefs.current[3].getBoundingClientRect().height / 2}px`,
        animation: addressSectionTimeline,
      })
    }

    // locoScroll.destroy()
    // locoScroll.init()
    // locoScroll.scroll.windowHeight = window.innerHeight
    // locoScroll.update()
    // locoScroll.on('scroll', ScrollTrigger.update)

    if (animationsNotEndedRefs.current[1]) {
      const contactFormSectionTimeline = gsap.timeline()
        .fromTo(contactFormSectionRefs.current[2], { y: -100, autoAlpha: 0 }, { y: 0, autoAlpha: 1, duration: 0.5, onStart: () => {
          animationsNotEndedRefs.current[1] = false
        } })
        .fromTo(contactFormSectionRefs.current[3], { y: -100, autoAlpha: 0 }, { y: 0, autoAlpha: 1, duration: 0.5 }, '<+0.2')
        .fromTo(contactFormSectionRefs.current[4], { y: 200, autoAlpha: 0 }, { y: contactFormSectionRefs.current[4].getBoundingClientRect().height + 5, autoAlpha: 1, duration: 0.5 }, '<')
      
      ScrollTrigger.create({
        trigger: heroSectionRefs.current[2],
        animation: contactFormSectionTimeline,
        start: `top -${heroSectionRefs.current[2].getBoundingClientRect().height - contactFormSectionRefs.current[5].getBoundingClientRect().height / 2}px`,
      })
    }

    if (animationsNotEndedRefs.current[2]) {
      ScrollTrigger.create({
        trigger: heroSectionRefs.current[2],
        animation: gsap.fromTo(contactFormSectionRefs.current[0], { rotationX: -100, autoAlpha: 0 }, { transformOrigin: '50% 50% -400', rotationX: 0, autoAlpha: 1, duration: 1, onComplete: () => {
          animationsNotEndedRefs.current[2] = false
        } }),
        start: `top -${heroSectionRefs.current[2].getBoundingClientRect().height - contactFormSectionRefs.current[5].getBoundingClientRect().height * 0.1}px`,
      })
    }

    if (animationsNotEndedRefs.current[3]) {
      const contactSectionTimeline = gsap.timeline()
        .fromTo(contactSectionRefs.current[0], { x: 300, autoAlpha: 0 }, { x: 0, autoAlpha: 1, duration: 1, onStart: () => {
          animationsNotEndedRefs.current[3] = false
        } })
        .fromTo(contactSectionRefs.current[1], { x: -300, autoAlpha: 0 }, { x: 0, autoAlpha: 1, duration: 1 }, '<+0.2')
        .fromTo(contactSectionRefs.current[2], { x: 100, y: -100, autoAlpha: 0 }, { x: 0, y: 0, autoAlpha: 1, duration: 1 }, '<+0.2')
        .fromTo(contactSectionRefs.current[3], { x: -100, y: 100, autoAlpha: 0 }, { x: 0, y: 0, autoAlpha: 1, duration: 1 }, '<+0.2')
      
      ScrollTrigger.create({
        trigger: heroSectionRefs.current[2],
        start: `top -${heroSectionRefs.current[2].getBoundingClientRect().height + contactFormSectionRefs.current[5].getBoundingClientRect().height + addressSectionRefs.current[3].getBoundingClientRect().height - contactSectionRefs.current[4].getBoundingClientRect().height / 2}px`,
        animation: contactSectionTimeline,
      })
    }

    ScrollTrigger.refresh(true)

    if (window.visualViewport.width > 700) {
      setScrollTriggersCreated(false)
    } else {
      setScrollTriggersCreated(true)
    }
  }, [locoScroll])

  const onResize = useCallback(() => {
    if (!scrollTriggersCreated) {
      setLayoutVariables()
      setScrollTriggers()
    } 
    
    if (!active && scrollToContactForm && locoScroll) {
      const offset = contactFormSectionRefs.current[contactFormSectionRefs.current.length - 1].getBoundingClientRect().height - window.visualViewport.height

      if (locoScroll?.scroll) {
        locoScroll.scrollTo(contactFormSectionRefs.current[contactFormSectionRefs.current.length - 1], -40, 1000, [0.25, 0.00, 0.35, 1.00], false, () => setScrollToContactForm(false))
      } else {
        gsap.to(window, {duration: 1, scrollTo: { y: contactFormSectionRefs.current[contactFormSectionRefs.current.length - 1].getBoundingClientRect().top + offset }, onComplete: () => setScrollToContactForm(false)});
      }
    }
  }, [setLayoutVariables, setScrollTriggers, scrollTriggersCreated, active, scrollToContactForm, locoScroll, setScrollToContactForm])

  useEffect(() => {
    if (locoScroll) {
      window.addEventListener('resize', onResize)
      onResize()
    }

    return () => window.removeEventListener('resize', onResize)
  }, [locoScroll, onResize])

  useEffect(() => {
    const triggerElement = heroSectionRefs.current[2]

    return () => {
      const animations = ScrollTrigger.getAll().filter(scrollTrigger =>
        scrollTrigger.vars.trigger === triggerElement)
  
      if (animations.length)
        animations.map(animation => animation.kill(true))
    }
  }, [])

  // useEffect(() => {

  // }, [active, scrollToContactForm, locoScroll, setScrollToContactForm])

  useEffect(() => {
    setNavbar2Refs([
      heroSectionRefs.current[heroSectionRefs.current.length - 1],
      contactFormSectionRefs.current[contactFormSectionRefs.current.length - 1],
      addressSectionRefs.current[addressSectionRefs.current.length - 1],
      contactSectionRefs.current[contactSectionRefs.current.length - 1]])
  }, [setNavbar2Refs])

  useEffect(() => {
    return () => {
      if (locoScroll?.scroll) {
        locoScroll.scrollTo(0, 0, 0, [0.05, 0.05, 0.05, 0.05], true, () => ScrollTrigger.refresh(true))
      } else {
        window.scrollTo(0, 0)
      }
    }
  }, [locoScroll])

  return (
    <div className="contact-container">
      <section className="viewport-height-catcher" ref={viewportHeightCatcherRef}></section>

      <section className="contact-hero-section" ref={(element) => addToRefsArray(heroSectionRefs, element)}>
        <div className="hero-background-1" ref={(element) => addToRefsArray(heroSectionRefs, element)}></div>
        <span className="greeting" ref={(element) => addToRefsArray(heroSectionRefs, element)}>Greetings</span>
      </section>

      <div className="hero-background-mask"></div>

      <section id="contact-form-section" className="contact-form-section" ref={(element) => addToRefsArray(contactFormSectionRefs, element)}>
        <div className="contact-form-outer-wrapper" ref={(element) => addToRefsArray(contactFormSectionRefs, element)}>
          <div className="contact-form-inner-wrapper" ref={(element) => addToRefsArray(contactFormSectionRefs, element)}>
            <form className="contact-form" onSubmit={handleSubmit}>
              <div className={`form-row-wrapper${nameFocused ? ' focused' : ''}${nameFocusOn ? ' active' : ''}${name.length > 0 ? ' initialized' : ''}${isEmpty(name) ? '' : tooLong(name, 255) ? '' : ' correct'}`}>
                <div className="input-label">Name<span className="error-message">&nbsp;-&nbsp;{isEmpty(name) ? `Can't be blank!` : tooLong(name, 255) ? 'Too long!' : ''}</span></div>
                <input name="name" type="text" value={name} onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur} />
                <div className="input-background"></div>
                <div className="border-bottom-first-half">
                  <div className="active-border"></div>
                </div>
                <div className="border-bottom-second-half">
                  <div className="active-border"></div>
                </div>
              </div>
              <div className={`form-row-wrapper${emailFocused ? ' focused' : ''}${emailFocusOn ? ' active' : ''}${email.length > 0 ? ' initialized' : ''}${isEmpty(email) ? '' : notValidEmail(email, 255) ? '' : ' correct'}`}>
                <div className="input-label">Email<span className="error-message">&nbsp;-&nbsp;{isEmpty(email) ? `Can't be blank!` : notValidEmail(email) ? 'Not valid!' : ''}</span></div>
                <input name="email" type="text" value={email} onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur} />
                <div className="background"></div>
                <div className="input-background"></div>
                <div className="border-bottom-first-half">
                  <div className="active-border"></div>
                </div>
                <div className="border-bottom-second-half">
                  <div className="active-border"></div>
                </div>
              </div>
              <div className={`form-row-wrapper${messageFocused ? ' focused' : ''}${messageFocusOn ? ' active' : ''}${message.length > 0 ? ' initialized' : ''}${isEmpty(message) ? '' : tooLong(message, 1000) ? '' : ' correct'}`}>
                <div className="input-label">Message<span className="error-message">&nbsp;-&nbsp;{isEmpty(message) ? `Can't be blank!` : tooLong(message, 1000) ? 'Too long!' : ''}</span></div>
                <textarea name="message" type="text" value={message} onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur} />
                <div className="background"></div>
                <div className="input-background"></div>
                <div className="border-bottom-first-half">
                  <div className="active-border"></div>
                </div>
                <div className="border-bottom-second-half">
                  <div className="active-border"></div>
                </div>
              </div>

              <div className="submit-button-wrapper">
                <button>
                  <span className="submit-button-label">Send</span>
                  <div className="submit-button-background"></div>
                </button>
                <div className="disabled"></div>
              </div>
            </form>
          </div>
        </div>

        <div className="contact-info-outer-wrapper">
          <div className="contact-info-inner-wrapper">
            <div className="contact-info">
              <div className="headline" ref={(element) => addToRefsArray(contactFormSectionRefs, element)}>Say hello!</div>
              <div className="call-to-action" ref={(element) => addToRefsArray(contactFormSectionRefs, element)}>If I can be of assistance please do not hesitate to contact me.</div>
            </div>
            <div className="call-to-action-reflection" ref={(element) => addToRefsArray(contactFormSectionRefs, element)}>If I can be of assistance please do not hesitate to contact me.<span className="cover"></span></div>
          </div>
        </div>
      </section>
      
      <section className="address-section" ref={(element) => addToRefsArray(addressSectionRefs, element)}>
        <div className="address-background" ref={(element) => addToRefsArray(addressSectionRefs, element)}></div>
        <div className="address-name" ref={(element) => addToRefsArray(addressSectionRefs, element)}>Tallinn</div>
        <div className="address-link-wrapper" ref={(element) => addToRefsArray(addressSectionRefs, element)}>
          <div className="arrow-right-wrapper">
            <div className="arrow-right"></div>
          </div>
          <div className="address-link">
            <a href="https://www.google.ee/maps/place/Tallinn/@59.4717925,24.5981621,11z/data=!3m1!4b1!4m5!3m4!1s0x46929499df5616bf:0x400b36d18fc6270!8m2!3d59.4369608!4d24.7535747" target="_blank" rel="noopener noreferrer">
              Maps
            </a>
            <span></span>
            <span></span>
          </div>
        </div>
      </section>

      <section className="contacts-section" ref={(element) => addToRefsArray(contactSectionRefs, element)}>
        <div className="contacts-wrapper">
          <div className="phone-number-wrapper">
            <div className="call-to-action" ref={(element) => addToRefsArray(contactSectionRefs, element)}>Give a call</div>
            <div className="phone-number-link-wrapper" ref={(element) => addToRefsArray(contactSectionRefs, element)}>
              <a className="phone-number-link" href="tel:+37255548445">+372 555 48 445</a>
              <span></span>
              <span></span>
            </div>
          </div>

          <div className="email-address-wrapper">
            <div className="call-to-action" ref={(element) => addToRefsArray(contactSectionRefs, element)}>Send an email</div>
            <div className="email-address-link-wrapper" ref={(element) => addToRefsArray(contactSectionRefs, element)}>
              <a className="email-address-link" href="mailto:jaak.kivinukk@gmail.com">jaak.kivinukk@gmail.com</a>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

Contact.defaultProps = {
  formDataInitialState: {
    name: '',
    email: '',
    message: '',
    nameFocused: false,
    emailFocused: false,
    messageFocused: false,
    nameFocusOn: false,
    emailFocusOn: false,
    messageFocusOn: false
  },
};

export default Contact
