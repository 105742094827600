import React, { useEffect, useRef, useState, forwardRef, useCallback } from 'react'

import { gsap } from 'gsap';
import { MorphSVGPlugin } from 'gsap/MorphSVGPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import '../css/Project.css'

gsap.registerPlugin(MorphSVGPlugin);

const AdaptiveComponent = ({
  displays:
  {
    phone,
    tablet,
    laptop,
    widescreen,
    narrowScreen
  },
  locoScroll,
  projectName,
  category,
  liveLink,
  githubLink,
  toolCount,
  panelIndex,
  triggerRef
}) => {
  const videoRefs = useRef([])
  const phoneRefs = useRef([])
  const tabletRefs = useRef([])
  const laptopRefs = useRef([])
  const widescreenRefs = useRef([])
  const narrowScreenRefs = useRef([])

  const sceneRefs = useRef([])

  const projectSectionRef = useRef(null)
  const viewportHeightCatcherRef = useRef(null)
  const displaysScrollTriggerRef = useRef(null)
  const toolsSceneScrollTriggerRef = useRef(null)
  const screenWrapperRef = useRef(null)
    
  const [projectVideos, setProjectVideos] = useState({
    phoneVideo: null,
    tabletVideo: null,
    laptopVideo: null,
    widescreenVideo: null,
    narrowScreenVideo: null
  })

  const [scrollTriggersCreated, setScrollTriggersCreated] = useState(false)

  const { phoneVideo, tabletVideo, laptopVideo, widescreenVideo, narrowScreenVideo } = projectVideos
  
  const sceneShapeSides = new Array(toolCount) 
  
  for (let i = 0; i < toolCount; i += 1)
    sceneShapeSides[i] = i

  const calculateWidth = (displayWidth, displayHeight) => {
    const width = screenWrapperRef.current.getBoundingClientRect().width
    const height = screenWrapperRef.current.getBoundingClientRect().height

    if (width === undefined || height === undefined) return '100%'

    return (width / height > 300 / 350) * height * (displayHeight / 350) * (displayWidth / displayHeight) +
      (width / height <= 300 / 350) * width * (displayWidth / 300)
  }

  const addToRefsArray = (refsArray, element) => {
    if (element && !refsArray.current.includes(element))
      refsArray.current.push(element)
  }

  const fetchVideos = useCallback(() => {
    if (!phoneVideo) {
      if (window.visualViewport.width > 700) {
        const phoneVideo = import(`../videos/${projectName}-phone.mp4`);
        const tabletVideo = import(`../videos/${projectName}-tablet.mp4`);
        const laptopVideo = import(`../videos/${projectName}-laptop.mp4`);
        const widescreenVideo = import(`../videos/${projectName}-widescreen.mp4`);
        const narrowScreenVideo = import(`../videos/${projectName}-narrow-screen.mp4`);
  
        Promise.all([phoneVideo, tabletVideo, laptopVideo, widescreenVideo, narrowScreenVideo]).then(values =>
          setProjectVideos({
            phoneVideo: values[0].default,
            tabletVideo: values[1].default,
            laptopVideo: values[2].default,
            widescreenVideo: values[3].default,
            narrowScreenVideo: values[4].default
          })
        )
      } else {
        const phoneVideo = import(`../videos/${projectName}-phone.mp4`);
        const tabletVideo = import(`../videos/${projectName}-tablet.mp4`);
        const laptopVideo = import(`../videos/${projectName}-laptop.mp4`);
  
        Promise.all([phoneVideo, tabletVideo, laptopVideo]).then(values =>
          setProjectVideos({
            ...projectVideos,
            phoneVideo: values[0].default,
            tabletVideo: values[1].default,
            laptopVideo: values[2].default
          })
        )
      }
    } else if (window.visualViewport.width > 700 && !widescreenVideo) {
      const widescreenVideo = import(`../videos/${projectName}-widescreen.mp4`);
      const narrowScreenVideo = import(`../videos/${projectName}-narrow-screen.mp4`);

      Promise.all([widescreenVideo, narrowScreenVideo]).then(values =>
        setProjectVideos({
          ...projectVideos,
          widescreenVideo: values[0].default,
          narrowScreenVideo: values[1].default
        })
      )
    }
  }, [projectName, phoneVideo, widescreenVideo, projectVideos])

  const setDisplayScrollTrigger = useCallback(() => {
    if (phoneVideo && locoScroll) {
      if (displaysScrollTriggerRef.current)
        displaysScrollTriggerRef.current.kill(true)

      const displaysTimeline = gsap.timeline({ repeat: -1, defaults: { duration: 0.67, ease: "expo.inOut" }})
        .add('start', 0)
        .to(phoneRefs.current[0], { opacity: 1, duration: 0 }, 'start')
        .to(phoneRefs.current[1], { opacity: 1, duration: 0 }, 'start')
        .to(phoneRefs.current[2], { opacity: 1, duration: 0 }, 'start')
        .to(phoneRefs.current[3], { opacity: 1, duration: 0 }, 'start')
        .to(phoneRefs.current[4], { opacity: 1, duration: 0 }, 'start')
        .to(phoneRefs.current[5], { opacity: 1, duration: 0 }, 'start')
        .to(videoRefs.current[0], { opacity: 1, duration: 0 }, 'start')

        // .add("tablet", 5)
        .add("tablet", 1)
        .to(videoRefs.current[0], { opacity: 0, duration: 0.5 }, 'tablet')  
        .to(videoRefs.current[1], { opacity: 1, delay: 0.3, duration: 0.3 }, 'tablet')  
        .to(phoneRefs.current[0], { morphSVG: tabletRefs.current[0] },  "tablet")
        .to(phoneRefs.current[1], { morphSVG: tabletRefs.current[1] },  "tablet")
        .to(phoneRefs.current[2], { morphSVG: tabletRefs.current[2] },  "tablet")
        .to(phoneRefs.current[3], { morphSVG: tabletRefs.current[3] },  "tablet")
        .to([phoneRefs.current[4], phoneRefs.current[5]], { scale: 0, y: '-2%' }, "tablet")
        
        // .add("laptop", 10.67)
        .add("laptop", 2.67)
        .to(videoRefs.current[1], { opacity: 0, duration: 0.5 }, 'laptop')  
        .to(videoRefs.current[2], { opacity: 1, delay: 0.3, duration: 0.3 }, 'laptop')  
        .to(phoneRefs.current[0], { morphSVG: laptopRefs.current[0] }, "laptop")
        .to(phoneRefs.current[1], { morphSVG: laptopRefs.current[1] }, "laptop")
        .to(phoneRefs.current[2], { scale: 0, y: -4 }, "laptop")
        .to(phoneRefs.current[3], { morphSVG: laptopRefs.current[2] }, "laptop")
        .fromTo(laptopRefs.current[3], { scale: 0, transformOrigin: "50% 50%" }, { scale: 1, opacity: 1 }, "laptop")

        if (widescreenVideo) {
          displaysTimeline
            // .add("widescreen", 16.34)
            .add("widescreen", 4.34)
            .to(videoRefs.current[2], { opacity: 0, duration: 0.5 }, 'widescreen')  
            .to(videoRefs.current[3], { opacity: 1, delay: 0.3, duration: 0.3 }, 'widescreen') 
            .to(phoneRefs.current[0], { morphSVG: widescreenRefs.current[0] }, "widescreen")
            .to(phoneRefs.current[1], { morphSVG: widescreenRefs.current[1] }, "widescreen")
            .to(phoneRefs.current[2], { morphSVG: phoneRefs.current[2], scale: 1, y: 0, opacity: 0, duration: 0 }, "widescreen")
            .to(phoneRefs.current[3], { morphSVG: widescreenRefs.current[2] }, "widescreen")
            .to(laptopRefs.current[3], { morphSVG: { shape: widescreenRefs.current[3], shapeIndex: 0 }, y: 0.5 }, "widescreen")
            .to([phoneRefs.current[4], phoneRefs.current[5]], { y: '-10%', duration: 0 }, "widescreen")
      
            // .add("narrowScreen", 22.01)
            .add("narrowScreen", 6.01)
            .to(videoRefs.current[3], { opacity: 0, duration: 0.5 }, 'narrowScreen')  
            .to(videoRefs.current[4], { opacity: 1, delay: 0.3, duration: 0.3 }, 'narrowScreen') 
            .to(phoneRefs.current[0], { morphSVG: narrowScreenRefs.current[0] }, "narrowScreen")
            .to(phoneRefs.current[1], { morphSVG: narrowScreenRefs.current[1] }, "narrowScreen")
            .to(phoneRefs.current[3], { scale: 0, y: -46 }, "narrowScreen")
            .to(laptopRefs.current[3], { morphSVG: narrowScreenRefs.current[2], y: 0 }, "narrowScreen")
            
            // .add("phone", 27.68)
            .add("phone", 7.68)
            .to(videoRefs.current[4], { opacity: 0, duration: 0.5 }, 'phone')
            .to(videoRefs.current[0], { opacity: 1, delay: 0.3, duration: 0.3 }, 'phone') 
            .to(phoneRefs.current[0], { morphSVG: phoneRefs.current[0] }, "phone")
            .to(phoneRefs.current[1], { morphSVG: phoneRefs.current[1] }, "phone")
            .to(laptopRefs.current[3], { morphSVG: phoneRefs.current[2], y: 0 }, "phone")
            .to(phoneRefs.current[3], { morphSVG: phoneRefs.current[3], y: 0, scale: 1, duration: 0.6 }, "phone")
            .to([phoneRefs.current[4], phoneRefs.current[5]], { scale: 1, y: 0 }, "phone")
        } else {
          displaysTimeline
            .add("phone", 4.34)
            .to(videoRefs.current[2], { opacity: 0, duration: 0.5 }, 'phone')
            .to(videoRefs.current[0], { opacity: 1, delay: 0.3, duration: 0.3 }, 'phone') 
            .to(phoneRefs.current[0], { morphSVG: phoneRefs.current[0] }, "phone")
            .to(phoneRefs.current[1], { morphSVG: phoneRefs.current[1] }, "phone")
            .to(laptopRefs.current[3], { morphSVG: "M87.2,149.44h3.6c1.1,0,2,0.9,2,2v0.37c0,1.1-0.9,2-2,2h-3.6 c-1.1,0-2-0.9-2-2v-0.37C85.2,150.34,86.1,149.44,87.2,149.44z", y: 0 }, "phone")
            .to(phoneRefs.current[3], { morphSVG: phoneRefs.current[3], y: 0, scale: 1, duration: 0.6 }, "phone")
            .to([phoneRefs.current[4], phoneRefs.current[5]], { scale: 1, y: 0 }, "phone")
        }

      let start, end

      if (window.visualViewport.width > 700) {
        start = `top ${(-1) * (panelIndex - (panelIndex > 0 ? 0.75 : 0)) * (window.visualViewport.height - 80) + 41}px`
        end = `+=${(window.visualViewport.height - 80) * 1.45}px`
      } else {
        start = `top ${(-1) * (panelIndex - (panelIndex > 0 ? 0.5 : 0)) * window.visualViewport.height + 1}px`
        end = `+=${window.visualViewport.height * 1.5}px`
      }

      displaysScrollTriggerRef.current = ScrollTrigger.create({
        animation: displaysTimeline,
        trigger: triggerRef,
        start,
        end,
        toggleActions: 'play pause play pause',
        onEnter: () => videoRefs.current.map(element => element.play()),
        onLeave: () => videoRefs.current.map(element => element.pause()),
        onEnterBack: () => videoRefs.current.map(element => element.play()),
        onLeaveBack: () => videoRefs.current.map(element => element.pause())
      })
    }
  }, [phoneVideo, widescreenVideo, locoScroll, panelIndex, triggerRef])

  const setToolsSceneScrollTrigger = useCallback(() => {
    if (locoScroll) {
      if (toolsSceneScrollTriggerRef.current)
        toolsSceneScrollTriggerRef.current.kill(true)

      const height = screenWrapperRef.current.getBoundingClientRect().height

      sceneRefs.current[sceneRefs.current.length - 1].style.width = `${height * 0.2}px`
      sceneRefs.current[sceneRefs.current.length - 1].style.height = `${height * 0.2}px`
      
      const toolsSceneTimeline = gsap.timeline({ repeat: -1 })

      if (toolCount === 4) {
        gsap.set(
          sceneRefs.current.slice(0, sceneRefs.current.length - 1), {
            transformOrigin: `50% 50% -${height * 0.1}px`
          })

        toolsSceneTimeline
          .fromTo(sceneRefs.current[0], { rotateY: 0 }, { rotateY: -360, duration: toolCount * 2.5, ease: 'linear' })
          .fromTo(sceneRefs.current[1], { rotateY: 90 }, { rotateY: -270, duration: toolCount * 2.5, ease: 'linear' }, '<')
          .fromTo(sceneRefs.current[2], { rotateY: 180 }, { rotateY: -180, duration: toolCount * 2.5, ease: 'linear' }, '<')
          .fromTo(sceneRefs.current[3], { rotateY: -90 }, { rotateY: -450, duration: toolCount * 2.5, ease: 'linear' }, '<')
      }
      
      if (toolCount === 5) {
        const translateZConstant = height * 0.1 / Math.tan(360 / 10 * Math.PI / 180)
        
        gsap.set(
          sceneRefs.current.slice(0, sceneRefs.current.length - 1), {
            transformOrigin: `50% 50% -${translateZConstant}px`
          })

        toolsSceneTimeline
          .fromTo(sceneRefs.current[0], { rotateY: 0 }, { rotateY: -360, duration: toolCount * 2.5, ease: 'linear' })
          .fromTo(sceneRefs.current[1], { rotateY: 360 / 5 }, { rotateY: 360 / 5 - 360, duration: toolCount * 2.5, ease: 'linear' }, '<')
          .fromTo(sceneRefs.current[2], { rotateY: 360 / 5 * 2 }, { rotateY: 360 / 5 * 2 - 360, duration: toolCount * 2.5, ease: 'linear' }, '<')
          .fromTo(sceneRefs.current[3], { rotateY: 360 / 5 * 3 }, { rotateY: 360 / 5 * 3 - 360, duration: toolCount * 2.5, ease: 'linear' }, '<')
          .fromTo(sceneRefs.current[4], { rotateY: 360 / 5 * 4 }, { rotateY: 360 / 5 * 4 - 360, duration: toolCount * 2.5, ease: 'linear' }, '<')
      }

      if (toolCount === 7) {
        const translateZConstant = height * 0.1 / Math.tan(360 / 14 * Math.PI / 180)
        
        gsap.set(
          sceneRefs.current.slice(0, sceneRefs.current.length - 1), {
            transformOrigin: `50% 50% -${translateZConstant}px`
          })

        toolsSceneTimeline
          .fromTo(sceneRefs.current[0], { rotateY: 0 }, { rotateY: -360, duration: toolCount * 2.5, ease: 'linear' })
          .fromTo(sceneRefs.current[1], { rotateY: 360 / 7 }, { rotateY: 360 / 7 - 360, duration: toolCount * 2.5, ease: 'linear' }, '<')
          .fromTo(sceneRefs.current[2], { rotateY: 360 / 7 * 2 }, { rotateY: 360 / 7 * 2 - 360, duration: toolCount * 2.5, ease: 'linear' }, '<')
          .fromTo(sceneRefs.current[3], { rotateY: 360 / 7 * 3 }, { rotateY: 360 / 7 * 3 - 360, duration: toolCount * 2.5, ease: 'linear' }, '<')
          .fromTo(sceneRefs.current[4], { rotateY: 360 / 7 * 4 }, { rotateY: 360 / 7 * 4 - 360, duration: toolCount * 2.5, ease: 'linear' }, '<')
          .fromTo(sceneRefs.current[5], { rotateY: 360 / 7 * 5 }, { rotateY: 360 / 7 * 5 - 360, duration: toolCount * 2.5, ease: 'linear' }, '<')
          .fromTo(sceneRefs.current[6], { rotateY: 360 / 7 * 6 }, { rotateY: 360 / 7 * 6 - 360, duration: toolCount * 2.5, ease: 'linear' }, '<')
      }

      let start, end

      if (window.innerWidth > 700) {
        start = `top ${(-1) * (panelIndex - (panelIndex > 0 ? 1 : 0)) * (window.innerHeight - 80) + 41}px`
        end = `+=${(window.innerHeight - (window.innerWidth < 700 ? 0 : 80)) * 1.75}px`
      } else {
        start = `top ${(-1) * (panelIndex - (panelIndex > 0 ? 0.3 : 0)) * window.innerHeight + 1}px`
        end = `+=${window.innerHeight * 1.5}px`
      }

      toolsSceneScrollTriggerRef.current = ScrollTrigger.create({
        animation: toolsSceneTimeline,
        trigger: triggerRef,
        start,
        end,
        toggleActions: 'play pause play pause'
      })
    }
  }, [locoScroll, panelIndex, toolCount, triggerRef])

  const setLayoutVariables = useCallback(() => {
    const height = viewportHeightCatcherRef.current.getBoundingClientRect().height

    if (window.visualViewport.width > 700) {
      projectSectionRef.current.style.height = `${height - 80}px`
    } else {
      projectSectionRef.current.style.height = `${height}px`
    }
  }, [])

  const onResize = useCallback(() => {
    if (!scrollTriggersCreated) {
      fetchVideos()
      setLayoutVariables()
      setDisplayScrollTrigger()
      setToolsSceneScrollTrigger()
    }

    if (window.visualViewport.width > 700) {
      if (scrollTriggersCreated)
        setScrollTriggersCreated(false)
    } else if (!scrollTriggersCreated) {
      setScrollTriggersCreated(true)
    }
  }, [scrollTriggersCreated, setDisplayScrollTrigger, setLayoutVariables, setToolsSceneScrollTrigger, fetchVideos])

  useEffect(() => {
    if (phoneVideo && locoScroll)
      onResize()

    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [onResize, phoneVideo, locoScroll])

  useEffect(() => {
    fetchVideos()
    setLayoutVariables()
  }, [fetchVideos, setLayoutVariables])

  return (
    <section className={`project-section ${projectName}`} ref={projectSectionRef}>
      <section className="viewport-height-catcher" ref={viewportHeightCatcherRef}></section>

      <div className={`category ${projectName}`}>{category}</div>

      <div className="screen-wrapper" ref={screenWrapperRef}>
        {phoneVideo &&
          <>
            <video className={`display-video ${projectName}-phone-video`} ref={(element) => addToRefsArray(videoRefs, element)} loop muted width={calculateWidth(phone.width, phone.height)} > 
              <source src={phoneVideo} type="video/mp4" />
            </video>

            <video className={`display-video tablet-video ${projectName}-tablet-video`} ref={(element) => addToRefsArray(videoRefs, element)} loop muted width={calculateWidth(tablet.width, tablet.height)} > 
              <source src={tabletVideo} type="video/mp4" /> 
            </video>

            <video className={`display-video laptop-video ${projectName}-laptop-video`} ref={(element) => addToRefsArray(videoRefs, element)}loop muted width={calculateWidth(laptop.width, laptop.height)} > 
              <source src={laptopVideo} type="video/mp4" /> 
            </video>

            {widescreenVideo && <>            
              <video className={`display-video widescreen-video ${projectName}-widescreen-video`} ref={(element) => addToRefsArray(videoRefs, element)} loop muted width={calculateWidth(widescreen.width, widescreen.height)} > 
                <source src={widescreenVideo} type="video/mp4" /> 
              </video>

              <video className={`display-video narrow-screen-video ${projectName}-narrow-screen-video`} ref={(element) => addToRefsArray(videoRefs, element)} loop muted width={calculateWidth(narrowScreen.width, narrowScreen.height)} > 
                <source src={narrowScreenVideo} type="video/mp4" /> 
              </video>
            </> }

          </>
        }

        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 350">
          <g>
            <path ref={(element) => addToRefsArray(phoneRefs, element)} d="M75,86h28c2.76,0,5,2.24,5,5v60c0,2.76-2.24,5-5,5H75 c-2.76,0-5-2.24-5-5V91C70,88.24,72.24,86,75,86z" />
            <path ref={(element) => addToRefsArray(phoneRefs, element)} d="M72.71,94.2h32.57v53.59H72.71V94.2z" />
            <path ref={(element) => addToRefsArray(phoneRefs, element)} d="M87.2,149.44h3.6c1.1,0,2,0.9,2,2v0.37c0,1.1-0.9,2-2,2h-3.6 c-1.1,0-2-0.9-2-2v-0.37C85.2,150.34,86.1,149.44,87.2,149.44z" />
            <path ref={(element) => addToRefsArray(phoneRefs, element)} d="M86.2,88.73h5.6c0.55,0,1,0.31,1,0.69l0,0c0,0.38-0.45,0.69-1,0.69 h-5.6c-0.55,0-1-0.31-1-0.69l0,0C85.2,89.04,85.65,88.73,86.2,88.73z" />
            <path ref={(element) => addToRefsArray(phoneRefs, element)} d="M75.69,87.09L75.69,87.09c0.75,0,1.35,0.61,1.35,1.37l0,0 c0,0.75-0.61,1.36-1.35,1.36l0,0c-0.75,0-1.35-0.61-1.35-1.36l0,0C74.34,87.7,74.95,87.09,75.69,87.09z" />
            <path ref={(element) => addToRefsArray(phoneRefs, element)} d="M78.96,87.64L78.96,87.64c0.45,0,0.81,0.37,0.81,0.82l0,0 c0,0.45-0.36,0.82-0.81,0.82l0,0c-0.45,0-0.81-0.37-0.81-0.82l0,0C78.14,88.01,78.5,87.64,78.96,87.64z" />

            <path ref={(element) => addToRefsArray(tabletRefs, element)} d="M51,64h75.63c2.76,0,5,2.24,5,5v102.99c0,2.76-2.24,5-5,5H51c-2.76,0-5-2.24-5-5V69C46,66.24,48.24,64,51,64z" />
            <path ref={(element) => addToRefsArray(tabletRefs, element)} d="M50.41,73.71h76.8v93.57h-76.8V73.71z" />
            <path ref={(element) => addToRefsArray(tabletRefs, element)} d="M88.61,169.05h1.3c1.1,0,2,0.9,2,2v1.3c0,1.1-0.9,2-2,2h-1.3c-1.1,0-2-0.9-2-2v-1.3 C86.61,169.95,87.51,169.05,88.61,169.05z" />
            <path ref={(element) => addToRefsArray(tabletRefs, element)} d="M89.37,67.53h0.21c0.55,0,1,0.45,1,1v0.21c0,0.55-0.45,1-1,1h-0.21c-0.55,0-1-0.45-1-1v-0.21 C88.37,67.98,88.82,67.53,89.37,67.53z" />

            <path ref={(element) => addToRefsArray(laptopRefs, element)} d="M20.4,75h138.65c0.55,0,1,0.45,1,1v94h-1H20.4h-1V76C19.4,75.45,19.85,75,20.4,75z" />
            <path ref={(element) => addToRefsArray(laptopRefs, element)} d="M23.21,78.8h133.04v84.89H23.21V78.8z" />
            <path ref={(element) => addToRefsArray(laptopRefs, element)} d="M90,75.7L90,75.7c0.55,0,1,0.45,1,1l0,0c0,0.55-0.45,1-1,1l0,0c-0.55,0-1-0.45-1-1l0,0C89,76.15,89.45,75.7,90,75.7z" />
            <path ref={(element) => addToRefsArray(laptopRefs, element)} d="M165.12,178.11c-5.53,0-148.92,0-152.96,0 S8,172.56,8,172.56h64.62l2.53,2.53h27.88l2.53-2.53h64.62C170.19,172.56,170.64,178.11,165.12,178.11z" />

            <path ref={(element) => addToRefsArray(widescreenRefs, element)} d="M2,50h173.62c1.1,0,2,0.9,2,2v101.46c0,1.1-0.9,2-2,2H2c-1.1,0-2-0.9-2-2V52C0,50.9,0.9,50,2,50z" />
            <path ref={(element) => addToRefsArray(widescreenRefs, element)} d="M4.16,54.16h169.29v92.97H4.16V54.16z" />
            <path ref={(element) => addToRefsArray(widescreenRefs, element)} d="M90.2,50.69L90.2,50.69c0.77,0,1.39,0.62,1.39,1.39l0,0c0,0.77-0.62,1.39-1.39,1.39l0,0c-0.77,0-1.39-0.62-1.39-1.39l0,0 C88.81,51.31,89.43,50.69,90.2,50.69z" />
            <path ref={(element) => addToRefsArray(widescreenRefs, element)} d="M109.12,178.11c-5.53,0-39.08,0-43.12,0s0.92-3.26,3.67-9.78 C72.33,162,72.62,155,72.62,155h2.53h25.88h2.53c0,0,1.1,10.04,3.31,14.12C110.42,175.67,114.64,178.11,109.12,178.11z" />

            <path ref={(element) => addToRefsArray(narrowScreenRefs, element)} d="M152.9,193.77H27V0h125.9V193.77z" />
            <path ref={(element) => addToRefsArray(narrowScreenRefs, element)} d="M143.43,184.47H36.47V7.75h106.96V184.47z" />
            <path ref={(element) => addToRefsArray(narrowScreenRefs, element)} d="M129,229H53l-0.23-2.97L50.15,194h2.53h76.88h2.53l-2.84,33.18 L129,229z" /> 
          </g>
        </svg>
      </div>

      <div className="project-links-wrapper">
        <div className="live-version-wrapper">
          <div className="live-version-link-wrapper">
            <a className="live-version-link" href={liveLink} target="_blank" rel="noopener noreferrer">Live Version</a>
            <span></span>
            <span></span>
          </div>
        </div>

        <div className="github-wrapper">
          <div className="github-link-wrapper">
            <a className="github-link" href={githubLink} target="_blank" rel="noopener noreferrer">Github</a>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>

      <div className="tools-wrapper">
        <div className="scene" ref={(element) => addToRefsArray(sceneRefs, element)}>
          <div className={`scene-shape-wrapper ${projectName}`}>
            {sceneShapeSides.map(index =>
              <div className={`tool-logo-wrapper logo${index + 1}-wrapper`} ref={(element) => addToRefsArray(sceneRefs, element)} key={index}>
                <div className="tool-logo"></div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

AdaptiveComponent.defaultProps = {
  displays: {
    phone: {
      width: 32.57,
      height: 53.59
    },
    tablet: {
      width: 76.8,
      height: 93.57
    },
    laptop: {
      width: 133.04,
      height: 84.89
    },
    widescreen: {
      width: 169.29,
      height: 92.97
    },
    narrowScreen: {
      width: 106.96,
      height: 176.72
    }
  }
}

// const AdaptiveWithDetector = withResizeDetector(AdaptiveComponent);

// const Project = forwardRef(({ locoScroll, projectName, category, liveLink, githubLink, toolCount, panelIndex, triggerRef }, ref) =>
//   <div className="project" ref={ref}>
//     <AdaptiveWithDetector locoScroll={locoScroll} projectName={projectName} category={category} liveLink={liveLink} githubLink={githubLink} toolCount={toolCount} panelIndex={panelIndex} triggerRef={triggerRef}/>
//   </div>
// )

const Project = forwardRef(({ locoScroll, projectName, category, liveLink, githubLink, toolCount, panelIndex, triggerRef }, ref) =>
  <div className="project" ref={ref}>
    <AdaptiveComponent locoScroll={locoScroll} projectName={projectName} category={category} liveLink={liveLink} githubLink={githubLink} toolCount={toolCount} panelIndex={panelIndex} triggerRef={triggerRef}/>
  </div>
)

export default Project
