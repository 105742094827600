import React, { useEffect, useRef } from 'react'
import { withResizeDetector } from 'react-resize-detector';

import { gsap } from 'gsap';
import { SplitText } from "gsap/SplitText";
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import '../css/Hobbies.css'

gsap.registerPlugin(SplitText);

const addToRefsArray = (refsArray, element) => {
  if (element && !refsArray.current.includes(element))
    refsArray.current.push(element)
}

const setStylesOnElement = (element, styles) => {
  Object.assign(element.style, styles);
}

const Hobbies = ({ width, height, targetRef, locoScroll, triggerRef }) => {
  const sectionRefs = useRef([])
  const hobbiesScrollTriggerRef = useRef(null)

  useEffect(() => {
    if (width && height && locoScroll && !hobbiesScrollTriggerRef.current) {
      const splitTextSectionHeadline = new SplitText(sectionRefs.current[0], { type: "words, chars" })
      const splitTextSectionText = new SplitText(sectionRefs.current[1], { type: "words, chars" })

      const sectionTimeline = gsap.timeline()
        .from(sectionRefs.current[12], { rotate: 1080, z: 1080, autoAlpha: 0, duration: 2, ease: "bounce.out" })
        .fromTo(sectionRefs.current[11], { xPercent: -50, yPercent: -50, rotate: 45 }, { xPercent: -50, yPercent: -50, rotate: 1485, duration: 2, ease: "bounce.out" }, '<')
        .from(sectionRefs.current[4], { rotate: 2160, z: -400, autoAlpha: 0, duration: 1 }, '<+0.5')
        .fromTo(sectionRefs.current[3], { xPercent: -50, yPercent: -50, rotate: 45 }, { xPercent: -50, yPercent: -50, rotate: 2205, duration: 1 }, '<')
        .from(sectionRefs.current[6], { rotate: 1080, z: 800, autoAlpha: 0, duration: 1 }, '<+0.2')
        .fromTo(sectionRefs.current[5], { xPercent: -50, yPercent: -50, rotate: 45 }, { xPercent: -50, yPercent: -50, rotate: 765, duration: 1 }, '<')
        .from(sectionRefs.current[7], { transformOrigin: '0% 100%', rotationX: 90, autoAlpha: 0, duration: 1 }, '<')
        .from(sectionRefs.current[8], { transformOrigin: '0% 0%', rotationX: -90, autoAlpha: 0, duration: 1 }, '<')
        .from(sectionRefs.current[9], { transformOrigin: '100% 50%', rotationY: 90, autoAlpha: 0, duration: 1 }, '<')
        .from(sectionRefs.current[10], { transformOrigin: '0% 100%', rotationY: -90, autoAlpha: 0, duration: 1 }, '<')

        .from(splitTextSectionHeadline.chars, {
          duration: 1, 
          scale: 4, 
          autoAlpha: 0, 
          rotationX: -180, 
          transformOrigin:"100% 50%", 
          ease:"back", 
          stagger: 0.02,
        }, '<+1')
          
        .from(splitTextSectionText.words, {
          duration: 1.5, 
          scale: 'random(0, 4)', 
          autoAlpha: 0, 
          x: 'random(-2000, 2000)', 
          y: 'random(-2000, 2000)', 
          z: 'random(-8000, 8000)', 
          stagger: 0.02,
        }, "<+0.3")
      
      gsap.set([sectionRefs.current[0], sectionRefs.current[1]], { visibility: 'visible' })

      hobbiesScrollTriggerRef.current = ScrollTrigger.create({
        animation: sectionTimeline,
        trigger: triggerRef.current,
        start: `top -${height * 0.75}px`,
      })
    }
  }, [width, height, locoScroll, targetRef, triggerRef])

  useEffect(() => {
    if (width && height) {
      const smallSquareDiagonalPercentageInitial = 0.2
      const smallSquareDiagonalPercentage = width / height >= 2.5 ? smallSquareDiagonalPercentageInitial : smallSquareDiagonalPercentageInitial * 14 / 25
      const horizontalAligningVariable = 0.5
      const verticalAligningVariable = 0.45

      if (width / height >= 2.5) {
        setStylesOnElement(
          sectionRefs.current[13],
          {
            top: `${(height - smallSquareDiagonalPercentage * height * 2) * verticalAligningVariable + height * 0.5 - smallSquareDiagonalPercentage * height / Math.sqrt(2) / 2}px`,
            left: `${(width - smallSquareDiagonalPercentage * height * 5) * horizontalAligningVariable + smallSquareDiagonalPercentage * height / 2 - smallSquareDiagonalPercentage * height / Math.sqrt(2) / 2}px`,
            width: `${5 * smallSquareDiagonalPercentage * height / Math.sqrt(2)}px`,
            height: `${5 * smallSquareDiagonalPercentage * height / Math.sqrt(2)}px`,
            transformOrigin: `10% 10%`,
            transform: 'rotate(-45deg)'
          }
        )
      } else {
        setStylesOnElement(
          sectionRefs.current[13],
          {
            top: `${(height - smallSquareDiagonalPercentage * width * 2) * verticalAligningVariable + height * 0.5 - smallSquareDiagonalPercentage * width / Math.sqrt(2) / 2}px`,
            left: `${(width - smallSquareDiagonalPercentage * width * 5) * horizontalAligningVariable + smallSquareDiagonalPercentage * width / 2 - smallSquareDiagonalPercentage * width / Math.sqrt(2) / 2}px`,
            width: `${5 * smallSquareDiagonalPercentage * width / Math.sqrt(2)}px`,
            height: `${5 * smallSquareDiagonalPercentage * width / Math.sqrt(2)}px`,
            transformOrigin: `10% 10%`,
            transform: 'rotate(-45deg)'
          }
        )
      }
    }
  }, [width, height])

  return (
    <section className="about-section hobbies-section" ref={targetRef}>
      <div className="text-wrapper" ref={(element) => addToRefsArray(sectionRefs, element)}>
        <div className="filler"></div>
        <div className="headline" ref={(element) => addToRefsArray(sectionRefs, element)}>Hobbies</div>
        <div className="text" ref={(element) => addToRefsArray(sectionRefs, element)}>Most of my free time I like to spend in a gym earning bit by bit the right to lift heavy doing Olympic lifts mixed with regular lifting or CrossFit. Add mountains, snow, and me together and I am going to be riding those mountains with a snowboard. When the sun is setting and all is said and done a good movie fits perfectly in the end to wrap the day up.</div>
        <div className="filler"></div>
      </div>

      <div className="section-background-wrapper" ref={(element) => addToRefsArray(sectionRefs, element)}>
        <div className="small-square small-square1" ref={(element) => addToRefsArray(sectionRefs, element)}>
          <div className="background small-square1-background" ref={(element) => addToRefsArray(sectionRefs, element)}></div>
        </div>
        <div className="small-square small-square2" ref={(element) => addToRefsArray(sectionRefs, element)}>
          <div className="background small-square2-background" ref={(element) => addToRefsArray(sectionRefs, element)}></div>
        </div>
        <div className="small-square small-square3" ref={(element) => addToRefsArray(sectionRefs, element)}>
          <div className="background small-square3-background"></div>
        </div>
        <div className="small-square small-square4" ref={(element) => addToRefsArray(sectionRefs, element)}>
          <div className="background small-square4-background"></div>
        </div>
        <div className="small-square small-square5" ref={(element) => addToRefsArray(sectionRefs, element)}>
          <div className="background small-square5-background"></div>
        </div>
        <div className="small-square small-square6" ref={(element) => addToRefsArray(sectionRefs, element)}>
          <div className="background small-square6-background"></div>
        </div>
        <div className="square big-square" ref={(element) => addToRefsArray(sectionRefs, element)}>
          <div className="background big-square-background" ref={(element) => addToRefsArray(sectionRefs, element)}></div>
        </div>
      </div>
    </section>
  )
}

export default withResizeDetector(Hobbies)
