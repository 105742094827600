import React, { useEffect, useRef, useCallback } from 'react'

import { gsap } from 'gsap';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import '../css/ScrollDown.css'

gsap.registerPlugin(DrawSVGPlugin);

const ScrollDown = ({ transition, targetRef }) => {
  const scrollDownMasterTimelineRef = useRef(null)
  const scrollDownScrollTriggerRef = useRef(null)

  const s1StrokeRef = useRef(null)
  const c1StrokeRef = useRef(null)
  const r1p1StrokeRef = useRef(null)
  const r1p2StrokeRef = useRef(null)
  const r1p3StrokeRef = useRef(null)
  const o1StrokeRef = useRef(null)
  const l1StrokeRef = useRef(null)
  const l2StrokeRef = useRef(null)
  const arrowP1StrokeRef = useRef(null)
  const arrowP2StrokeRef = useRef(null)

  const { active, shrinking } = transition

  useEffect(() => {
    if (!active && !shrinking) {
      if (!scrollDownMasterTimelineRef.current) {
        gsap.set('.scroll-down-container', { height: window.innerHeight })
        
        const s1Timeline = gsap.timeline()
          .fromTo(s1StrokeRef.current, { drawSVG: '0% 0%', autoAlpha: 0 }, { drawSVG: '0% 10%', autoAlpha: 1, duration: 0.2, ease: 'linear' })
          .to(s1StrokeRef.current, { drawSVG: '90% 100%', duration: 1, ease: 'linear' })
          .to(s1StrokeRef.current, { drawSVG: '100% 100%', duration: 0.2, ease: 'linear' })
          .to(s1StrokeRef.current, { drawSVG: '90% 100%', duration: 0.2, ease: 'linear' })
          .to(s1StrokeRef.current, { drawSVG: '45% 55%', duration: 0.5, ease: 'linear' })
          .to(s1StrokeRef.current, { drawSVG: '0% 100%', duration: 0.5, ease: 'linear' })
          .to(s1StrokeRef.current, { drawSVG: '45% 55%', duration: 0.5, ease: 'linear' }, '+=0.3')
          .to(s1StrokeRef.current, { drawSVG: '0% 10%', duration: 0.5, ease: 'linear' })
          .to(s1StrokeRef.current, { drawSVG: '0% 0%', duration: 0.2, ease: 'linear' })
        
        const c1Timeline = gsap.timeline()
          .fromTo(c1StrokeRef.current, { drawSVG: '0% 0%', autoAlpha: 0 }, { drawSVG: '0% 10%', autoAlpha: 1, duration: 0.2, ease: 'linear' })
          .to(c1StrokeRef.current, { drawSVG: '90% 100%', duration: 1, ease: 'linear' })
          .to(c1StrokeRef.current, { drawSVG: '100% 100%', duration: 0.2, ease: 'linear' })
          .to(c1StrokeRef.current, { drawSVG: '90% 100%', duration: 0.2, ease: 'linear' })
          .to(c1StrokeRef.current, { drawSVG: '45% 55%', duration: 0.5, ease: 'linear' })
          .to(c1StrokeRef.current, { drawSVG: '0% 100%', duration: 0.5, ease: 'linear' })
          .to(c1StrokeRef.current, { drawSVG: '45% 55%', duration: 0.5, ease: 'linear' }, '+=0.3')
          .to(c1StrokeRef.current, { drawSVG: '0% 10%', duration: 0.5, ease: 'linear' })
          .to(c1StrokeRef.current, { drawSVG: '0% 0%', duration: 0.2, ease: 'linear' })
        
        const r1Timeline = gsap.timeline()
          .fromTo(r1p1StrokeRef.current, { drawSVG: '0% 0%', autoAlpha: 0 }, { drawSVG: '0% 30%', autoAlpha: 1, duration: 0.2, ease: 'linear' })
          .to(r1p1StrokeRef.current, { drawSVG: '70% 100%' ,duration: 0.3, ease: 'linear' })
          .to(r1p1StrokeRef.current, { drawSVG: '100% 100%', duration: 0.03, ease: 'linear' })
          .fromTo(r1p2StrokeRef.current, { drawSVG: '0% 0%', autoAlpha: 0 }, { drawSVG: '0% 15%', autoAlpha: 1, duration: 0.07, ease: 'linear' }, '<')
          .to(r1p2StrokeRef.current, { drawSVG: '85% 100%', duration: 0.6, ease: 'linear' })
          .to(r1p2StrokeRef.current, { drawSVG: '100% 100%', duration: 0.2, ease: 'linear' })
          .to(r1p2StrokeRef.current, { drawSVG: '85% 100%', duration: 0.2, ease: 'linear' })
          .to(r1p2StrokeRef.current, { drawSVG: '0% 15%', duration: 0.5, ease: 'linear' })
          .to(r1p2StrokeRef.current, { drawSVG: '0% 100%', duration: 0.5, ease: 'linear' })
          .to(r1p1StrokeRef.current, { drawSVG: '0% 100%', duration: 0.5, ease: 'linear' }, '<')
          .fromTo(r1p3StrokeRef.current, { drawSVG: '0% 0%', autoAlpha: 0 }, { drawSVG: '0% 100%', autoAlpha: 1, duration: 0.2, ease: 'linear' }, '<+0.3')
          .to(r1p3StrokeRef.current, { drawSVG: '0% 0%', duration: 0.2, ease: 'linear' }, '+=0.3')
          .to(r1p2StrokeRef.current, { drawSVG: '0% 15%', duration: 0.5, ease: 'linear' }, '<')
          .to(r1p1StrokeRef.current, { drawSVG: '100% 100%', duration: 0.5, ease: 'linear' }, '<')
          .to(r1p2StrokeRef.current, { drawSVG: '0% 0%', duration: 0.06, ease: 'linear' })
          .to(r1p1StrokeRef.current, { drawSVG: '70% 100%', duration: 0.03, ease: 'linear' }, '<')
          .to(r1p1StrokeRef.current, { drawSVG: '0% 30%', duration: 0.5, ease: 'linear' })
          .to(r1p1StrokeRef.current, { drawSVG: '0% 0%', duration: 0.2, ease: 'linear' })
        
        const o1Timeline = gsap.timeline()
          .fromTo(o1StrokeRef.current, { drawSVG: '100% 100%', autoAlpha: 0 }, { drawSVG: '90% 100%', autoAlpha: 1, duration: 0.2, ease: 'linear' })
          .to(o1StrokeRef.current, { drawSVG: '0% 10%', duration: 1, ease: 'linear' })
          .to(o1StrokeRef.current, { drawSVG: '0% 0%', duration: 0.2, ease: 'linear' })
          .to(o1StrokeRef.current, { drawSVG: '0% 10%', duration: 0.2, ease: 'linear' })
          .to(o1StrokeRef.current, { drawSVG: '45% 55%', duration: 0.5, ease: 'linear' })
          .to(o1StrokeRef.current, { drawSVG: '0% 100%', duration: 0.5, ease: 'linear' })
          .to(o1StrokeRef.current, { drawSVG: '45% 55%', duration: 0.5, ease: 'linear' }, '+=0.3')
          .to(o1StrokeRef.current, { drawSVG: '90% 100%', duration: 0.5, ease: 'linear' })
          .to(o1StrokeRef.current, { drawSVG: '100% 100%', duration: 0.2, ease: 'linear' })
        
        const l1Timeline = gsap.timeline()
          .fromTo(l1StrokeRef.current, { drawSVG: '0% 0%', autoAlpha: 0 }, { drawSVG: '0% 10%', autoAlpha: 1, duration: 0.2, ease: 'linear' })
          .to(l1StrokeRef.current, { drawSVG: '90% 100%', duration: 1, ease: 'linear' })
          .to(l1StrokeRef.current, { drawSVG: '100% 100%', duration: 0.2, ease: 'linear' })
          .to(l1StrokeRef.current, { drawSVG: '90% 100%', duration: 0.2, ease: 'linear' })
          .to(l1StrokeRef.current, { drawSVG: '45% 55%', duration: 0.5, ease: 'linear' })
          .to(l1StrokeRef.current, { drawSVG: '0% 100%', duration: 0.5, ease: 'linear' })
          .to(l1StrokeRef.current, { drawSVG: '45% 55%', duration: 0.5, ease: 'linear' }, '+=0.3')
          .to(l1StrokeRef.current, { drawSVG: '0% 10%', duration: 0.5, ease: 'linear' })
          .to(l1StrokeRef.current, { drawSVG: '0% 0%', duration: 0.2, ease: 'linear' })
        
        const l2Timeline = gsap.timeline()
          .fromTo(l2StrokeRef.current, { drawSVG: '100% 100%', autoAlpha: 0 }, { drawSVG: '90% 100%', autoAlpha: 1, duration: 0.2, ease: 'linear' })
          .to(l2StrokeRef.current, { drawSVG: '0% 10%', duration: 1, ease: 'linear' })
          .to(l2StrokeRef.current, { drawSVG: '0% 0%', duration: 0.2, ease: 'linear' })
          .to(l2StrokeRef.current, { drawSVG: '0% 10%', duration: 0.2, ease: 'linear' })
          .to(l2StrokeRef.current, { drawSVG: '45% 55%', duration: 0.5, ease: 'linear' })
          .to(l2StrokeRef.current, { drawSVG: '0% 100%', duration: 0.5, ease: 'linear' })
          .to(l2StrokeRef.current, { drawSVG: '45% 55%', duration: 0.5, ease: 'linear' }, '+=0.3')
          .to(l2StrokeRef.current, { drawSVG: '90% 100%', duration: 0.5, ease: 'linear' })
          .to(l2StrokeRef.current, { drawSVG: '100% 100%', duration: 0.2, ease: 'linear' })
  
        const arrowTimeline = gsap.timeline()
          .fromTo(arrowP1StrokeRef.current, { drawSVG: '0% 0%', autoAlpha: 0 }, { drawSVG: '0% 100%', autoAlpha: 1, duration: 0.7, ease: 'linear' })
          .fromTo(arrowP2StrokeRef.current, { drawSVG: '0% 0%', autoAlpha: 0 }, { drawSVG: '0% 100%', autoAlpha: 1, duration: 0.7, ease: 'linear' }, '<')
          .to(arrowP1StrokeRef.current, { drawSVG: '56% 100%', duration: 0.3, ease: 'linear' })
          .to(arrowP2StrokeRef.current, { drawSVG: '56% 100%', duration: 0.3, ease: 'linear' }, '<')
          .to(arrowP1StrokeRef.current, { drawSVG: '100% 100%', duration: 0.3, ease: 'linear' }, '+=0.3')
          .to(arrowP2StrokeRef.current, { drawSVG: '100% 100%', duration: 0.3, ease: 'linear' }, '<')
          .fromTo(arrowP1StrokeRef.current, { drawSVG: '0% 0%' }, { drawSVG: '0% 100%', duration: 0.7, ease: 'linear' })
          .fromTo(arrowP2StrokeRef.current, { drawSVG: '0% 0%' }, { drawSVG: '0% 100%', duration: 0.7, ease: 'linear' }, '<')
          .to(arrowP1StrokeRef.current, { drawSVG: '56% 100%', duration: 0.3, ease: 'linear' })
          .to(arrowP2StrokeRef.current, { drawSVG: '56% 100%', duration: 0.3, ease: 'linear' }, '<')
          .to(arrowP1StrokeRef.current, { drawSVG: '100% 100%', duration: 0.3, ease: 'linear' }, '+=0.3')
          .to(arrowP2StrokeRef.current, { drawSVG: '100% 100%', duration: 0.3, ease: 'linear' }, '<')
  
          scrollDownMasterTimelineRef.current = gsap.timeline({ repeat: -1 })
          .add(s1Timeline)
          .add(c1Timeline)
          .add(r1Timeline)
          .add(o1Timeline)
          .add(l1Timeline)
          .add(l2Timeline)
          .add(arrowTimeline)
  
        s1Timeline.startTime(0)
        c1Timeline.startTime(0)
        r1Timeline.startTime(0)
        o1Timeline.startTime(0)
        l1Timeline.startTime(0)
        l2Timeline.startTime(0)
        arrowTimeline.startTime(0)
      }

      scrollDownScrollTriggerRef.current = ScrollTrigger.create({
        animation: scrollDownMasterTimelineRef.current,
        trigger: targetRef,
        start: `top 1px`,
        end: `+=${targetRef.getBoundingClientRect().height - window.innerHeight}`,
        toggleActions: 'play reset play reset',
      })
    } else if (active && scrollDownMasterTimelineRef.current) {
      scrollDownMasterTimelineRef.current.pause()
      scrollDownMasterTimelineRef.current.progress(0)
    }
  }, [active, shrinking, targetRef])

  const onResize = useCallback(() => {
    gsap.set('.scroll-down-container', { height: window.innerHeight })

    if (scrollDownScrollTriggerRef.current) {
      scrollDownScrollTriggerRef.current.kill(true)

      scrollDownScrollTriggerRef.current = ScrollTrigger.create({
        animation: scrollDownMasterTimelineRef.current,
        trigger: targetRef,
        start: `top 1px`,
        end: `+=${targetRef.getBoundingClientRect().height - window.innerHeight}`,
        toggleActions: 'play reset play reset',
      })
    }
  }, [targetRef])

  useEffect(() => {
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [onResize])

  return (
    <div className="scroll-down-container">
      <div className="scroll-down-wrapper">
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 252.34 83.78">
          <defs>
            <clipPath id="scroll-clip-path" transform="translate(0.68 2.17)">
              <path id="scroll-s1-clip" className="scroll-1" d="M25.05,40.31a9.25,9.25,0,0,0,9.26-9.22,9.27,9.27,0,0,0-9.26-9.26H10.93a10.58,10.58,0,0,1-7.72-3.19A10.48,10.48,0,0,1,0,10.93,10.52,10.52,0,0,1,3.21,3.21,10.52,10.52,0,0,1,10.93,0H25.05a10.52,10.52,0,0,1,7.72,3.21A10.52,10.52,0,0,1,36,10.93c0,.29-.16.44-.47.44h-.76a.39.39,0,0,1-.44-.44A8.94,8.94,0,0,0,31.6,4.4a8.9,8.9,0,0,0-6.55-2.73H10.93A8.92,8.92,0,0,0,4.41,4.38,8.92,8.92,0,0,0,1.7,10.93a8.94,8.94,0,0,0,2.69,6.52,8.88,8.88,0,0,0,6.54,2.71H25.05a10.52,10.52,0,0,1,7.72,3.21,10.86,10.86,0,0,1,0,15.42A10.54,10.54,0,0,1,25.05,42H10.93a10.54,10.54,0,0,1-7.72-3.19A10.48,10.48,0,0,1,0,31.09a.41.41,0,0,1,.47-.47h.76a.41.41,0,0,1,.47.47A8.9,8.9,0,0,0,4.39,37.6a8.88,8.88,0,0,0,6.54,2.71Z"/>
            </clipPath>
            <clipPath id="scroll-clip-path-2" transform="translate(0.68 2.17)">
              <path id="scroll-c1-clip" className="scroll-1" d="M61.11,1.7a19.1,19.1,0,0,0-7.19,1.52,19.32,19.32,0,0,0-6.11,4.14,18.92,18.92,0,0,0-5,8.67,19.12,19.12,0,0,0,0,10,18.92,18.92,0,0,0,5,8.67,19.29,19.29,0,0,0,6.11,4.13,18.73,18.73,0,0,0,7.19,1.52,19.61,19.61,0,0,0,7.24-1.25,19.1,19.1,0,0,0,6.24-3.87l.53-.53a.4.4,0,0,1,.64,0l.56.53a.44.44,0,0,1,0,.64l-.56.56A21,21,0,0,1,61.49,42h-.38a20.38,20.38,0,0,1-7.83-1.65,20.93,20.93,0,0,1-6.64-4.5,20.51,20.51,0,0,1-5.45-9.42A21,21,0,0,1,46.64,6.15a20.9,20.9,0,0,1,6.64-4.49A20.39,20.39,0,0,1,61.11,0,21.1,21.1,0,0,1,69,1.38,20.45,20.45,0,0,1,75.76,5.6a1.54,1.54,0,0,0,.19.17,2.08,2.08,0,0,1,.21.21,1.42,1.42,0,0,0,.16.17.45.45,0,0,1,.14.33.44.44,0,0,1-.14.32l-.56.53a.4.4,0,0,1-.64,0l-.18-.18L74.75,7a.75.75,0,0,0-.16-.15A18.55,18.55,0,0,0,68.38,3,19.7,19.7,0,0,0,61.11,1.7Z"/>
            </clipPath>
            <clipPath id="scroll-clip-path-3" transform="translate(0.68 2.17)">
              <path id="scroll-r1-p3-clip" className="scroll-1" d="M108.52,29.39l9.81,11.86a.36.36,0,0,1,.06.47A.45.45,0,0,1,118,42h-1a.39.39,0,0,1-.35-.17l-9.87-11.9-.16-.23,1.75-.54Z"/>
            </clipPath>
            <clipPath id="scroll-clip-path-4" transform="translate(0.68 2.17)">
              <path id="scroll-r1-p2-clip" className="scroll-1" d="M84.17,1.67H103.3a13.27,13.27,0,0,1,6.77,1.8A13.44,13.44,0,0,1,115,8.38a13.27,13.27,0,0,1,1.8,6.77,13.17,13.17,0,0,1-2.71,8.07,13.22,13.22,0,0,1-7,4.88.58.58,0,0,0-.2.06.69.69,0,0,1-.21.06,2.91,2.91,0,0,0-.38.08,14.11,14.11,0,0,1-3,.33H83.88V30.3H103.3a15,15,0,0,0,3.46-.39l1.76-.52a14.82,14.82,0,0,0,7.2-5.54,14.82,14.82,0,0,0,2.73-8.7,14.77,14.77,0,0,0-2-7.59A15.22,15.22,0,0,0,110.89,2a14.77,14.77,0,0,0-7.59-2H83.87V1.68Z"/>
            </clipPath>
            <clipPath id="scroll-clip-path-5" transform="translate(0.68 2.17)">
              <path id="scroll-r1-p1-clip" className="scroll-1" d="M84.17,1.67V41.55c0,.29-.16.43-.47.43h-.76c-.31,0-.47-.14-.47-.43V.47s0,0,0,0,0,0,0,0v0a.14.14,0,0,1,0-.11l0,0a.09.09,0,0,0,0-.06l0,0,.06-.06,0,0s0,0,.08,0h.09l.06,0h1.23Z"/>
            </clipPath>
            <clipPath id="scroll-clip-path-6" transform="translate(0.68 2.17)">
              <path id="scroll-o1-clip" className="scroll-1" d="M144.55,1.67a18.68,18.68,0,0,0-7.5,1.54,19.45,19.45,0,0,0-6.15,4.13,19.8,19.8,0,0,0-4.12,6.17,19.2,19.2,0,0,0,1.06,17.18,19.27,19.27,0,0,0,7,7,19.38,19.38,0,0,0,19.36,0,19.19,19.19,0,0,0,7-7,19.2,19.2,0,0,0,1.06-17.18,19.8,19.8,0,0,0-4.12-6.17,19.45,19.45,0,0,0-6.15-4.13,18.68,18.68,0,0,0-7.5-1.54h-.28l0-1.67h.3a20.56,20.56,0,0,1,8.15,1.67,21.29,21.29,0,0,1,6.69,4.48,21.6,21.6,0,0,1,4.47,6.7,20.92,20.92,0,0,1-1.15,18.68,20.74,20.74,0,0,1-7.64,7.64,21,21,0,0,1-18.68,1.14,21.24,21.24,0,0,1-6.69-4.46,21.08,21.08,0,0,1-4.49-6.7,20.7,20.7,0,0,1,0-16.29,20.7,20.7,0,0,1,11.2-11.19A20.48,20.48,0,0,1,144.55,0Z"/>
            </clipPath>
            <clipPath id="scroll-clip-path-7" transform="translate(0.68 2.17)">
              <path id="scroll-l1-clip" className="scroll-1" d="M172,42c-.31,0-.47-.14-.47-.43V.47A.42.42,0,0,1,172,0h.76a.41.41,0,0,1,.47.47V40.31H207c.31,0,.47.15.47.44v.8c0,.29-.16.43-.47.43Z"/>
            </clipPath>
            <clipPath id="scroll-clip-path-8" transform="translate(0.68 2.17)">
              <path id="scroll-l2-clip" className="scroll-1" d="M214,42c-.31,0-.46-.14-.46-.43V.47A.41.41,0,0,1,214,0h.77a.41.41,0,0,1,.46.47V40.31H249c.32,0,.47.15.47.44v.8c0,.29-.15.43-.47.43Z"/>
            </clipPath>
          </defs>
          <polyline id="arrow-p1" className="scroll-2" ref={arrowP1StrokeRef} points="0.68 58.54 75.42 58.54 125.92 82.88"/>
          <polyline id="arrow-p2" className="scroll-2" ref={arrowP2StrokeRef} points="250.17 58.54 175.43 58.54 124.92 82.88"/>
          <g id="scroll-s1">
            <g className="scroll-3">
              <polyline id="scroll-s1-stroke" className="scroll-4" ref={s1StrokeRef} points="1.5 31.42 1.5 34.61 1.97 36.39 3 38.45 4.32 40.14 6.47 42.12 9.1 42.87 11.45 43.24 16.23 43.24 27.87 43.24 30.13 42.3 32.47 40.8 34.16 38.92 35.29 36.58 35.76 34.51 35.76 32.17 35.1 29.54 33.6 26.91 30.5 24.38 28.44 23.63 26.28 23.17 10.98 23.17 6.76 21.94 4.13 19.87 2.06 16.77 1.5 12.83 1.97 9.92 3.38 7.01 6 4.76 9.1 3.35 12.57 2.79 17.27 2.79 27.22 2.79 30.03 3.92 31.53 4.86 33.32 6.45 34.72 8.14 35.38 9.74 35.76 11.89 35.76 14.71"/>
            </g>
          </g>
          <g id="scroll-c1">
            <g className="scroll-5">
              <polyline id="scroll-c1-stroke" className="scroll-4" ref={c1StrokeRef} points="77.14 10.39 75.36 7.67 71.89 5.33 67.67 3.84 64.38 3.07 60.53 3.07 56.97 3.84 53.12 5.14 49.74 7.39 46.64 10.39 44.2 13.58 42.7 17.32 42.05 21.28 42.05 24.38 42.42 27.66 43.36 30.2 44.58 33.2 47.3 36.77 49.74 39.11 52.65 40.99 56.59 42.58 60.06 43.24 63.3 43.24 66.63 42.77 70.39 41.46 74.14 39.49 76.21 37.7 77.61 36.2"/>
            </g>
          </g>
          <g id="scroll-r1">
          <g id="scroll-r1-p1">
              <g className="scroll-8">
                <line id="scroll-r1-p1-stroke" className="scroll-4" ref={r1p1StrokeRef} x1="84" y1="44.58" x2="84" y2="1.89"/>
              </g>
            </g>
            <g id="scroll-r1-p2">
              <g className="scroll-7">
                <polyline id="scroll-r1-p2-stroke" className="scroll-4" ref={r1p2StrokeRef} points="84.13 2.98 104.61 2.98 107 3.4 109.54 4.17 111.86 5.48 113.9 7.06 115.52 8.96 116.79 10.97 117.77 13.57 118.27 16.6 118.09 19.24 117.49 22.05 116.33 24.48 114.64 26.98 112.63 28.67 109.96 30.36 107.28 31.13 104.26 31.66 84.34 31.66"/>
              </g>
            </g>
            <g id="scroll-r1-p3">
              <g className="scroll-6">
                <line id="scroll-r1-p3-stroke" className="scroll-4" ref={r1p3StrokeRef} x1="107.84" y1="30.95" x2="118.72" y2="44.56"/>
              </g>
            </g>
          </g>
          <g id="scroll-o1">
            <g className="scroll-9">
              <polyline id="scroll-o1-stroke" className="scroll-4" ref={o1StrokeRef} points="145.44 2.92 143.78 3.1 141.3 3.4 138.56 4.16 136.74 4.93 133.96 6.59 131.78 8.1 129.77 10.12 128.49 11.9 127.38 13.78 126.17 16.53 125.55 18.85 125.2 20.96 125.06 23.6 125.2 25.63 125.69 28 126.39 30.2 127.57 32.72 128.88 34.95 129.95 36.41 131.45 37.86 133.05 39.28 134.47 40.25 136.23 41.23 138.03 41.92 140.4 42.66 142.46 43.14 144.31 43.35 146.4 43.35 148.3 43.08 150.15 42.73 152.14 42.1 153.88 41.38 155.82 40.43 157.81 38.97 159.74 37.22 161.38 35.32 162.72 33.26 163.95 30.87 164.56 29.2 165.07 27.14 165.37 25.12 165.37 23.37 165.29 21.32 164.92 18.78 164.25 16.37 163.37 14.38 161.87 11.74 160.41 9.89 158.49 7.91 155.96 6.08 153.44 4.81 151.24 3.84 148.81 3.24 147.13 3.08 146.16 3.01 144.68 3.01"/>
            </g>
          </g>
          <g id="scroll-l1">
            <g className="scroll-10">
              <polyline id="scroll-l1-stroke" className="scroll-4" ref={l1StrokeRef} points="172.92 1.5 172.92 43.24 208.82 43.24"/>
            </g>
          </g>
          <g id="scroll-l2">
            <g className="scroll-11">
              <polyline id="scroll-l2-stroke" className="scroll-4" ref={l2StrokeRef} points="214.88 1.57 214.88 43.17 250.84 43.17"/>
            </g>
          </g>
        </svg>
      </div>
    </div>
  )
}

export default ScrollDown
