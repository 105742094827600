import React, { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import '../css/Transition.css'

const addToRefsArray = (refsArray, element) => {
  if (element && !refsArray.current.includes(element))
    refsArray.current.push(element)
}

const Transition = ({ setTransition, transition, componentPath }) => {
  const history = useHistory()

  const transitionContainerRef = useRef(null)
  const widePillarRef = useRef(null)
  const thinPillarRefs = useRef([])

  const { active, shrinking, historySteps } = transition

  useEffect(() => {
    if (active) {
      const duration = 1.5

      if (shrinking) {
        gsap.timeline()
          .to(transitionContainerRef.current, { rotation: 0, ease: 'slow(0.7, 0.7, false)', duration: duration, onStart: () => transitionContainerRef.current.style.backgroundColor = 'transparent' }, '+=0.5')
          .to([widePillarRef.current, thinPillarRefs.current], { scaleX: 0, ease: 'slow(0.7, 0.7, false)', duration: duration,
            onComplete: () =>
              {
                transitionContainerRef.current.style.pointerEvents = 'none'
                setTransition({ active: false, shrinking: false, historySteps: null })
              }
            }, '<')
      } else {
        ScrollTrigger.getAll().map(scrollTrigger => scrollTrigger.kill(false))
  
        gsap.timeline()
          .to(transitionContainerRef.current, { rotation: -20, ease: 'slow(0.7, 0.7, false)', duration: duration, onStart: () => { 
            if (historySteps < 0) {
              window.history.go(1)
            } else if (historySteps === 1) {
              window.history.go(-1)
            }
           } })
          .to(widePillarRef.current, { scaleX: 1, ease: 'slow(0.7, 0.7, false)', duration: duration }, '<')
          .to(thinPillarRefs.current, { scaleX: 1, ease: 'slow(0.7, 0.7, false)', duration: duration,
            onComplete: () =>
              {
                transitionContainerRef.current.style.backgroundColor = '#ffffff'
                transitionContainerRef.current.style.pointerEvents = 'auto'
  
                switch (historySteps) {
                  case -2:
                    window.history.go(-2)
                    break
                  case -1:
                    window.history.go(-3)
                    break
                  case 1:
                    window.history.go(3)
                    break
                  case 5:
                    window.history.pushState({ state: 1 }, null, document.URL);
                    history.push(componentPath)
                    break
                  default:
                    break
                }
  
                gsap.timeline()
                .to(transitionContainerRef.current, { rotation: 0, ease: 'slow(0.7, 0.7, false)', duration: duration, onStart: () => transitionContainerRef.current.style.backgroundColor = 'transparent' }, '+=0.5')
                .to([widePillarRef.current, thinPillarRefs.current], { scaleX: 0, ease: 'slow(0.7, 0.7, false)', duration: duration,
                  onComplete: () =>
                    {
                      transitionContainerRef.current.style.pointerEvents = 'none'
                      setTransition({ active: false, shrinking: false, historySteps: null })
                    }
                  }, '<')
              }
            }, '<')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, shrinking, setTransition])

  return (
    <div className='transition-container' ref={transitionContainerRef}>
      <div className="thin-pillar pillar1" ref={(element) => addToRefsArray(thinPillarRefs, element)}></div>
      <div className="thin-pillar pillar2" ref={(element) => addToRefsArray(thinPillarRefs, element)}></div>
      <div className="thin-pillar pillar3" ref={(element) => addToRefsArray(thinPillarRefs, element)}></div>
      <div className="wide-pillar pillar4" ref={widePillarRef}></div>
      <div className="thin-pillar pillar5" ref={(element) => addToRefsArray(thinPillarRefs, element)}></div>
      <div className="thin-pillar pillar6" ref={(element) => addToRefsArray(thinPillarRefs, element)}></div>
      <div className="thin-pillar pillar7" ref={(element) => addToRefsArray(thinPillarRefs, element)}></div>
    </div>
  )
}

export default Transition
