import React, { useEffect, useCallback, useRef } from 'react'

import { gsap } from 'gsap';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';
import { SplitText } from "gsap/SplitText";
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import '../css/Home.css';

gsap.registerPlugin(DrawSVGPlugin, SplitText);

const Home = ({ locoScroll, transition, setComponent, setScrollToContactForm, setNavbar2Refs }) => {
  const homeSectionRef = useRef(null)

  const viewportHeightCatcherRef = useRef(null)
  
  const infoRef = useRef(null)
  const greetingRef = useRef(null)
  const greetingInnerRef = useRef(null)
  
  const firstNameWrapperRef = useRef(null)
  const lastNameWrapperRef = useRef(null)
  const callToActionRef = useRef(null)
  const professionRef = useRef(null)
  const professionInnerRef = useRef(null)
  
  const portraitBackgroundRef = useRef(null)
  const portraitCoverRef = useRef(null)
  const portraitWrapperRef = useRef(null)
  const portrait1WrapperRef = useRef(null)
  const portrait1Ref = useRef(null)
  const portrait2Ref = useRef(null)
  
  const firstNameRefs = useRef([])
  const lastNameRefs = useRef([])

  const portraitScrollerTriggerRef = useRef(null)
  const infoScrollerTriggerRef = useRef(null)

  const { active, shrinking } = transition

  const addToRefsArray = (refsArray, element) => {
    if (element && !refsArray.current.includes(element))
      refsArray.current.push(element)
  }

  const setLayoutVariables = useCallback(() => {
    const viewportHeight = viewportHeightCatcherRef.current.getBoundingClientRect().height

    if (window.visualViewport.width > 700) {
      gsap.set(homeSectionRef.current, { minHeight: viewportHeight - 80 })
      gsap.set(infoRef.current, { height: viewportHeight - 80 })
      gsap.set([portraitWrapperRef.current, portrait1WrapperRef.current, portrait2Ref.current], { width: Math.max(420, viewportHeight - 80) })
      gsap.set([portraitBackgroundRef.current, portraitCoverRef.current, portraitWrapperRef.current], { height: Math.max(450, viewportHeight - 80) })
    } else {
      gsap.set(homeSectionRef.current, { minHeight: viewportHeight })
      gsap.set(infoRef.current, { height: viewportHeight })
      gsap.set([portraitWrapperRef.current, portrait1WrapperRef.current, portrait2Ref.current], { width: Math.max(450, viewportHeight) })
      gsap.set([portraitBackgroundRef.current, portraitCoverRef.current, portraitWrapperRef.current], { height: Math.max(450, viewportHeight) })
    }

    if (homeSectionRef.current.getBoundingClientRect().height >= (window.innerHeight - (window.innerWidth <= 700 ? 0 : 80)) * 2) {
      setNavbar2Refs([portraitWrapperRef.current, infoRef.current])
    } else {
      setNavbar2Refs([])
    }
  }, [setNavbar2Refs])

  const openingAnimation = useCallback(() => {
    if (locoScroll?.scroll)
      locoScroll.update()

    // Split text

    const splitTextTimeline = gsap.timeline();

    const splitTextGreeting = new SplitText(greetingRef.current, { type: "words, chars" })
    
    splitTextTimeline.from(splitTextGreeting.chars, {
      duration: 1, 
      scale: 4, 
      autoAlpha: 0, 
      rotationX: -180, 
      transformOrigin:"100% 50%", 
      ease:"back", 
      stagger: 0.02,
      onStart: () => gsap.set(infoRef.current, { autoAlpha: 1 })
    })
    
    const mySplitTextProfession = new SplitText(professionRef.current, { type: "words, chars" })
    
    splitTextTimeline.from(mySplitTextProfession.chars, {
      duration: 1, 
      scale: 4, 
      autoAlpha: 0, 
      rotationX: -180, 
      transformOrigin:"100% 50%", 
      ease:"back", 
      stagger: 0.02,
    }, ">+1")

    // Transitioning

    const homeSectionWidth = homeSectionRef.current.getBoundingClientRect().width
    const homeSectionHeight = homeSectionRef.current.getBoundingClientRect().height
    
    const infoWidth = infoRef.current.getBoundingClientRect().width
    const infoInnerWidth = greetingRef.current.getBoundingClientRect().width
    const greetingWidth = greetingInnerRef.current.getBoundingClientRect().width
    const firstNameWidth = firstNameWrapperRef.current.getBoundingClientRect().width
    const lastNameWidth = lastNameWrapperRef.current.getBoundingClientRect().width
    const professionWidth = professionInnerRef.current.getBoundingClientRect().width

    const portraitCoverWidth = portraitCoverRef.current.getBoundingClientRect().width
    const portraitWrapperWidth = portraitWrapperRef.current.getBoundingClientRect().width

    const introTranslateXStart = homeSectionWidth - (infoWidth - infoInnerWidth) - Math.max(lastNameWidth, professionWidth)
    
    const greetingTranslateXStart = homeSectionWidth - introTranslateXStart - (infoWidth - infoInnerWidth) - greetingWidth
    const firstNameTranslateXStart = homeSectionWidth - introTranslateXStart - (infoWidth - infoInnerWidth) - firstNameWidth
    const lastNameTranslateXStart = homeSectionWidth - introTranslateXStart - (infoWidth - infoInnerWidth) - lastNameWidth
    const professionTranslateXStart = homeSectionWidth - introTranslateXStart - (infoWidth - infoInnerWidth) - professionWidth

    gsap.set(infoRef.current, { x: introTranslateXStart })
    gsap.set(greetingRef.current, { x: greetingTranslateXStart })
    gsap.set(firstNameWrapperRef.current, { x: firstNameTranslateXStart })
    gsap.set(lastNameWrapperRef.current, { x: lastNameTranslateXStart })
    gsap.set(professionRef.current, { x: professionTranslateXStart })

    const portraitTransitionTimeline = gsap.timeline()

    if (homeSectionHeight >= (window.innerHeight - (window.innerWidth <= 700 ? 0 : 80)) * 2) {
      gsap.set(portraitCoverRef.current, { x: homeSectionWidth / 2 - 0.5, autoAlpha: 1 })
      
      portraitTransitionTimeline
        .to(portraitCoverRef.current, { x: 0, duration: 1, ease: 'linear', onStart: () => gsap.set(portrait2Ref.current, { autoAlpha: 1 }), onComplete: () => {
          gsap.set([portraitBackgroundRef.current, portrait1WrapperRef.current], { autoAlpha: 1 })
          gsap.set(portraitCoverRef.current, { opacity: 0 })
        }})
        .to(portrait1Ref.current, { autoAlpha: 1, ease: 'linear' })
    } else {
      gsap.set(portraitCoverRef.current, { x: introTranslateXStart, autoAlpha: 1 })
      
      portraitTransitionTimeline
        .to(portraitCoverRef.current, { x: homeSectionWidth - portraitCoverWidth - portraitWrapperWidth / 2, duration: 1, delay: 3, ease: 'linear' })
        .to(portraitCoverRef.current, { x: 0, duration: 1, ease: 'linear', onComplete: () => {
          gsap.set([portraitBackgroundRef.current, portrait1WrapperRef.current], { autoAlpha: 1 })
          gsap.set(portraitCoverRef.current, { opacity: 0 })
        }})
        .to(portrait1Ref.current, { autoAlpha: 1, ease: 'linear' }, `>+0.7`)
    }

    portraitScrollerTriggerRef.current = ScrollTrigger.create({
      animation: portraitTransitionTimeline,
      trigger: portraitWrapperRef.current,
      start: `top 50%`,
    })

    portraitScrollerTriggerRef.current.disable()

    const infoTransitionTimeline = gsap.timeline()
      .to(infoRef.current, { duration: 1, x: (homeSectionWidth - infoWidth), ease: 'linear', onComplete: () => gsap.set([portrait1WrapperRef.current, portrait2Ref.current], { autoAlpha: 1 }) }, `>`)
      .to(greetingRef.current, { duration: 1, x: greetingTranslateXStart + (introTranslateXStart - (homeSectionWidth - infoWidth)), ease: 'linear' }, `<`)
      .to(firstNameWrapperRef.current, { duration: 1, x: firstNameTranslateXStart + (introTranslateXStart - (homeSectionWidth - infoWidth)), ease: 'linear' }, `<`)
      .to(lastNameWrapperRef.current, { duration: 1, x: lastNameTranslateXStart + (introTranslateXStart - (homeSectionWidth - infoWidth)), ease: 'linear' }, `<`)
      .to(professionRef.current, { duration: 1, x: professionTranslateXStart + (introTranslateXStart - (homeSectionWidth - infoWidth)), ease: 'linear' }, `<`)

    if (!(homeSectionHeight >= (window.innerHeight - (window.innerWidth <= 700 ? 0 : 80)) * 2))
      infoTransitionTimeline
        .to(infoRef.current, { duration: 1.5, x: 0, ease: 'linear', onComplete: () => gsap.to(portraitBackgroundRef.current, { autoAlpha: 1, duration: 1 }) }, `>`)
  
    infoTransitionTimeline  
      .to(greetingRef.current, { duration: 1.5, x: 0, ease: 'linear' }, `>`)
      .to(firstNameWrapperRef.current, { duration: 1.5, x: 0, ease: 'linear' }, `<`)
      .to(lastNameWrapperRef.current, { duration: 1.5, x: 0, ease: 'linear' }, `<`)
      .to(professionRef.current, { duration: 1.5, x: 0, ease: 'linear' }, `<`)
      .from(callToActionRef.current, { autoAlpha: 0, duration: 1 }, `>-0.2`)

    // First name

    const nameAnimationDuration = 1

    const j1 = {
      duration: nameAnimationDuration,
    };

    const a1 = {
      p1_duration: nameAnimationDuration * 0.88,
      p2_duration: nameAnimationDuration * 0.88,
      p3_duration: nameAnimationDuration * 0.12,
      p4_duration: nameAnimationDuration * 0.12,
    }

    const a2 = a1

    const k1 = {
      p1_duration: nameAnimationDuration,
      p2_duration: nameAnimationDuration * 0.53,
      p3_duration: nameAnimationDuration * 0.47,
    };
    
    const j1Timeline = gsap.timeline()
      .from(firstNameRefs.current[0], { duration: j1.duration, drawSVG: '0% 0%' }, `>`)
    
    const a1Timeline = gsap.timeline()
      .from(firstNameRefs.current[1], { duration: a1.p1_duration, drawSVG: '0% 0%' }, '>')
      .from(firstNameRefs.current[2], { duration: a1.p2_duration, drawSVG: '0% 0%' }, `<`)
      .from(firstNameRefs.current[3], { duration: a1.p3_duration, drawSVG: '0% 0%' }, `>`)
      .from(firstNameRefs.current[4], { duration: a1.p4_duration, drawSVG: '0% 0%' }, `<`)
    
    const a2Timeline = gsap.timeline()
      .from(firstNameRefs.current[5], { duration: a1.p1_duration, drawSVG: '0% 0%' }, '>')
      .from(firstNameRefs.current[6], { duration: a2.p2_duration, drawSVG: '0% 0%' }, `<`)
      .from(firstNameRefs.current[7], { duration: a1.p3_duration, drawSVG: '0% 0%' }, `>`)
      .from(firstNameRefs.current[8], { duration: a2.p4_duration, drawSVG: '0% 0%' }, `<`)
    
    const k1Timeline = gsap.timeline()
      .from(firstNameRefs.current[9], { duration: k1.p1_duration, drawSVG: '0% 0%' }, '>')
      .from(firstNameRefs.current[10], { duration: k1.p2_duration, drawSVG: '0% 0%' }, `<`)
      .from(firstNameRefs.current[11], { duration: k1.p3_duration, drawSVG: '0% 0%' }, `-=${k1.p1_duration - k1.p2_duration}`)

    // Last name

    const k2 = {
      p1_duration: nameAnimationDuration,
      p2_duration: nameAnimationDuration * 0.51,
      p3_duration: nameAnimationDuration * 0.08,
      p4_duration: nameAnimationDuration * 0.49,
    };

    const i1 = {
      duration: nameAnimationDuration,
    };

    const v1 = {
      p1_duration: nameAnimationDuration,
      p2_duration: nameAnimationDuration,
    };

    const i2 = i1

    const n1 = {
      p1_duration: nameAnimationDuration,
      p2_duration: nameAnimationDuration,
      p3_duration: nameAnimationDuration,
    };

    const u1 = {
      p1_duration: nameAnimationDuration,
      p2_duration: nameAnimationDuration,
    };

    const k3 = k2
    const k4 = k2
    
    const k2Timeline = gsap.timeline()
      .from(lastNameRefs.current[0], { duration: k2.p1_duration, drawSVG: '100% 100%' }, '>')
      .from(lastNameRefs.current[1], { duration: k2.p2_duration, drawSVG: '0% 0%' }, `<`)
      .from(lastNameRefs.current[2], { duration: k2.p3_duration, drawSVG: '0% 0%' }, `-=${k2.p1_duration - k2.p2_duration}`)
      .from(lastNameRefs.current[3], { duration: k2.p4_duration, drawSVG: '0% 0%' }, `-=${k2.p1_duration - k2.p2_duration}`)
    
    const i1Timeline = gsap.timeline()
      .from(lastNameRefs.current[4], { duration: i1.duration, drawSVG: '0% 0%' })
    
    const v1Timeline = gsap.timeline()
      .from(lastNameRefs.current[5], { duration: v1.p1_duration, drawSVG: '0% 0%' })
      .from(lastNameRefs.current[6], { duration: v1.p2_duration, drawSVG: '0% 0%' }, `<`)
    
    const i2Timeline = gsap.timeline()
      .from(lastNameRefs.current[7], { duration: i2.duration, drawSVG: '0% 0%' })
    
    const n1Timeline = gsap.timeline()
      .from(lastNameRefs.current[8], { duration: n1.p1_duration, drawSVG: '0% 0%' })
      .from(lastNameRefs.current[9], { duration: n1.p2_duration, drawSVG: '0% 0%' }, `<`)
      .from(lastNameRefs.current[10], { duration: n1.p3_duration, drawSVG: '0% 0%' }, `<`)
    
    const u1Timeline = gsap.timeline()
      .from(lastNameRefs.current[11], { duration: u1.p1_duration, drawSVG: '0% 0%' })
      .from(lastNameRefs.current[12], { duration: u1.p2_duration, drawSVG: '0% 0%' }, '<')
    
    const k3Timeline = gsap.timeline()
      .from(lastNameRefs.current[13], { duration: k3.p1_duration, drawSVG: '100% 100%' }, '>')
      .from(lastNameRefs.current[14], { duration: k3.p2_duration, drawSVG: '0% 0%' }, `<`)
      .from(lastNameRefs.current[15], { duration: k3.p3_duration, drawSVG: '0% 0%' }, `-=${k3.p1_duration - k3.p2_duration}`)
      .from(lastNameRefs.current[16], { duration: k3.p4_duration, drawSVG: '0% 0%' }, `-=${k3.p1_duration - k3.p2_duration}`)
    
    const k4Timeline = gsap.timeline()
      .from(lastNameRefs.current[17], { duration: k4.p1_duration, drawSVG: '100% 100%' }, '>')
      .from(lastNameRefs.current[18], { duration: k4.p2_duration, drawSVG: '0% 0%' }, `<`)
      .from(lastNameRefs.current[19], { duration: k4.p3_duration, drawSVG: '0% 0%' }, `-=${k4.p1_duration - k4.p2_duration}`)
      .from(lastNameRefs.current[20], { duration: k4.p4_duration, drawSVG: '0% 0%' }, `-=${k4.p1_duration - k4.p2_duration}`)

    const infoMasterTimeline = gsap.timeline()
      .add(splitTextTimeline)
      .add(infoTransitionTimeline)

      .add(j1Timeline)
      .add(a1Timeline)
      .add(a2Timeline)
      .add(k1Timeline)

      .add(k2Timeline)
      .add(i1Timeline)
      .add(v1Timeline)
      .add(i2Timeline)
      .add(n1Timeline)
      .add(u1Timeline)
      .add(k3Timeline)
      .add(k4Timeline)
    
    j1Timeline.startTime(1)
    a1Timeline.startTime(1)
    a2Timeline.startTime(1)
    k1Timeline.startTime(1)

    k2Timeline.startTime(1)
    i1Timeline.startTime(1)
    v1Timeline.startTime(1)
    i2Timeline.startTime(1)
    n1Timeline.startTime(1)
    u1Timeline.startTime(1)
    k3Timeline.startTime(1)
    k4Timeline.startTime(1)
    
    splitTextTimeline.startTime(0)
    infoTransitionTimeline.startTime(3)

    infoScrollerTriggerRef.current = ScrollTrigger.create({
      animation: infoMasterTimeline,
      trigger: infoRef.current,
      start: `top 50%`
    })

    infoScrollerTriggerRef.current.disable()
  }, [locoScroll])

  const handleLeaveAMessageClick = () => {
    setScrollToContactForm(true)
    setComponent({ path: '/contact' })
  }

  useEffect(() => {
    document.fonts.ready.then(() => {
      if (locoScroll) {
        openingAnimation()
      }
    });
  }, [locoScroll, openingAnimation])
  
  useEffect(() => {
    setLayoutVariables()
    window.addEventListener('resize', setLayoutVariables)
    return () => window.removeEventListener('resize', setLayoutVariables)
  }, [setLayoutVariables])

  useEffect(() => {
    if (!active && !shrinking) {
      if (infoScrollerTriggerRef.current)
        infoScrollerTriggerRef.current.enable()

      if (portraitScrollerTriggerRef.current)
        portraitScrollerTriggerRef.current.enable()
    }
  }, [active, shrinking])

  useEffect(() => {
    return () => {
      if (locoScroll?.scroll) {
        locoScroll.scrollTo(0, 0, 0, [0.05, 0.05, 0.05, 0.05], true, () => ScrollTrigger.refresh(true))
      } else {
        window.scrollTo(0, 0)
      }
    }
  }, [locoScroll])

  const nameSvg = 
    <div className="name-wrapper">
      <div className='first-name-wrapper' ref={firstNameWrapperRef}>
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 420.46 241.83">
          <defs>
            <clipPath id="clip-path" transform="translate(5.72 16.04)">
              <path id="j1-clip" className="cls-1" d="M0,198.47l8.53-5c1.35,2.33,2.45,4.46,3.76,6.44,6,9.09,14.47,13.23,25.33,12.29s18.06-6.58,22.28-16.39a54.17,54.17,0,0,0,4-18.46c.16-2.66.18-5.33.18-8q0-82.86,0-165.72c0-3.49,0-3.49,3.53-3.49,2.84,0,5.67.09,8.5,0C78,0,78.93.58,78.67,2.62a20.84,20.84,0,0,0,0,2.5q0,83.37,0,166.73c0,7.75-.89,15.29-4.26,22.36A36.34,36.34,0,0,1,52.2,213.8c-11.23,3.7-22.56,4-33.82,0A35,35,0,0,1,0,198.47Z"/>
            </clipPath>
            <clipPath id="clip-path-2" transform="translate(5.72 16.04)">
              <path id="a1-p1-clip" className="cls-1" d="M108.6,176.18c-2,11.66-4.08,23.28-6.07,34.93-.63,3.67-.77,3.84-5.06,3,.62-3.64,1.21-7.3,1.86-11q6.08-34.29,12.19-68.58,4.62-25.93,9.28-51.86,5-27.76,9.85-55.55c.45-2.54,1-5.07,1.37-7.62,1-6.35,2.14-12.71-1.53-19.39h9.1l0,6-.66,0q-14.48,81.77-29,163.72Z"/>
            </clipPath>
            <clipPath id="clip-path-3" transform="translate(5.72 16.04)">
              <path id="a1-p3-clip" className="cls-1" d="M139.59,174.39H111c-1.3,0-2.13.23-2.4,1.8h-.4l1.27-6.36h30.45v4.56Z"/>
            </clipPath>
            <clipPath id="clip-path-4" transform="translate(5.72 16.04)">
              <path id="a1-p4-clip" className="cls-1" d="M139.23,174.39v-4.56h31l1.32,6.68h-.4c-.23-1.51-.84-2.14-2.42-2.13q-14.56,0-29.14,0Z"/>
            </clipPath>
            <clipPath id="clip-path-5" transform="translate(5.72 16.04)">
              <path id="a1-p2-clip" className="cls-1" d="M169.76,169.83,139.56,6.14l-.66,0h0l.17-6h4c6.56,0,10.94,3.46,12.18,9.92,2.88,15,5.6,30.1,8.39,45.16q4.29,23.19,8.57,46.4,5,26.88,10,53.75c3.14,16.85,6.24,33.71,9.54,50.53.56,2.86,2,5.54,3.15,8.39H174.54c3.69-9.63-.45-18.57-1.6-27.7-.42-3.38-1.27-6.71-1.79-10.08Z"/>
            </clipPath>
            <clipPath id="clip-path-6" transform="translate(5.72 16.04)">
              <path id="a2-p1-clip" className="cls-1" d="M213,176.06c-2,11.66-4.08,23.28-6.07,34.93-.63,3.67-.78,3.84-5.06,3,.61-3.64,1.2-7.3,1.85-11q6.09-34.29,12.19-68.58,4.62-25.93,9.29-51.86,5-27.76,9.85-55.55c.45-2.54.95-5.07,1.36-7.62,1-6.35,2.14-12.71-1.52-19.39H244l0,6-.66,0q-14.47,81.77-29,163.72Z"/>
            </clipPath>
            <clipPath id="clip-path-7" transform="translate(5.72 16.04)">
              <path id="a2-p3-clip" className="cls-1" d="M244,174.27H215.4c-1.31,0-2.13.23-2.4,1.8h-.4l1.26-6.36h30.45v4.56Z"/>
            </clipPath>
            <clipPath id="clip-path-8" transform="translate(5.72 16.04)">
              <path id="a2-p4-clip" className="cls-1" d="M243.63,174.27v-4.56h31l1.31,6.68h-.39c-.24-1.51-.84-2.14-2.42-2.13q-14.58,0-29.15,0Z"/>
            </clipPath>
            <clipPath id="clip-path-9" transform="translate(5.72 16.04)">
              <path id="a2-p2-clip" className="cls-1" d="M274.15,169.71,244,6l-.66,0h0l.18-6h3.94c6.56,0,10.94,3.46,12.18,9.92C262.48,25,265.2,40,268,55.09q4.29,23.19,8.58,46.4,5,26.88,10,53.75c3.14,16.85,6.23,33.71,9.54,50.53.56,2.86,2.05,5.54,3.15,8.39H278.94c3.69-9.63-.46-18.57-1.6-27.7-.43-3.38-1.27-6.71-1.79-10.08Z"/>
            </clipPath>
            <clipPath id="clip-path-10" transform="translate(5.72 16.04)">
              <path id="k1-p1-clip" className="cls-1" d="M335.68,112V6.81c0-6.33,0-6.33-6.45-6.33H318.46a5.5,5.5,0,0,0,.14,1.36c2.34,4.71,2.51,9.75,2.51,14.87q0,97.25,0,194.49v3.47h14.59v-3.32c0-27.83.22-55.67-.16-83.49Z"/>
            </clipPath>
            <clipPath id="clip-path-11" transform="translate(5.72 16.04)">
              <path id="k1-p3-clip" className="cls-1" d="M341.74,111.8c.67,1.21,1.13,2,1.52,2.75C353.52,134.92,364,155.19,374,175.7c6.22,12.81,14.22,24.84,17.89,39h19.9c-1.21-1.64-2.15-2.79-2.94-4a117.76,117.76,0,0,1-6.45-10.38Q376.9,149.93,351.6,99.48a4.6,4.6,0,0,1,.2-5l-.42-.36-10,17.46Z"/>
            </clipPath>
            <clipPath id="clip-path-12" transform="translate(5.72 16.04)">
              <path id="k1-p2-clip" className="cls-1" d="M341.74,111.8c-2.9,5.42-6.31,9.64-6.22,16.06h-.47l.14-15.89.49,0,.46.19c.52-.79,1.06-1.56,1.54-2.37L399.74,3.45C401.68.11,401.85.05,406.3.81c-.44.86-.8,1.65-1.23,2.39Q378.46,48.85,351.8,94.45Z"/>
            </clipPath>
            <mask id='jaak-mask'>
              <g id="j1">
                <g className="cls-2">
                  <path id="j1-stroke" ref={(element) => addToRefsArray(firstNameRefs, element)} className="cls-3" d="M71.51-8V182.49s-2.93,10.18-4.71,13.2c-2,3.39-7.45,9.18-10.38,11.79a30.3,30.3,0,0,1-4.87,3.39A39.26,39.26,0,0,1,45.36,213a66.35,66.35,0,0,1-8.17,1.26,42.73,42.73,0,0,1-5.77,0,50.77,50.77,0,0,1-8.3-1.54A37.23,37.23,0,0,1,17.49,210a43,43,0,0,1-5.92-4.36,56.46,56.46,0,0,1-4.36-5.49c-1.3-1.75-2.65-4.38-4.93-7.18" transform="translate(5.72 16.04)"/>
                </g>
              </g>
              <g id="a1">
                <g id="a1-p1">
                  <g className="cls-4">
                    <polyline id="a1-p1-stroke" ref={(element) => addToRefsArray(firstNameRefs, element)} className="cls-5" points="141.39 14.82 141.39 24.68 140.62 32.91 137.03 51.7 130.55 88.31 124.57 122.31 118.72 154.83 114.01 180.94 109.29 207.13 105 231.62"/>
                  </g>
                </g>
                <g id="a1-p2">
                  <g className="cls-9">
                    <path id="a1-p2-stroke" ref={(element) => addToRefsArray(firstNameRefs, element)} className="cls-10" d="M145.6.12l3.09,13.94,4.37,23.72,5.7,30.83,4.71,25.69,5,26.47,6,30.9S181.78,192,184,205.52c.09.56.31,1.68.35,2.25.16,2,.56,4.48.56,6.51" transform="translate(5.72 16.04)"/>
                  </g>
                </g>
                <g id="a1-p3">
                  <g className="cls-6">
                    <line id="a1-p3-stroke" ref={(element) => addToRefsArray(firstNameRefs, element)} className="cls-7" x1="113.92" y1="188.28" x2="146.01" y2="188.1"/>
                  </g>
                </g>
                <g id="a1-p4">
                  <g className="cls-8">
                    <line id="a1-p4-stroke" ref={(element) => addToRefsArray(firstNameRefs, element)} className="cls-7" x1="176.89" y1="188.99" x2="144.51" y2="188.99"/>
                  </g>
                </g>
              </g>
              <g id="a2">
                <g id="a2-p1">
                  <g className="cls-11">
                    <polyline id="a2-p1-stroke" ref={(element) => addToRefsArray(firstNameRefs, element)} className="cls-5" points="245.78 14.7 245.78 24.55 245.01 32.79 241.42 51.59 234.94 88.19 228.96 122.19 223.12 154.71 218.4 180.82 213.69 207.01 209.39 231.5"/>
                  </g>
                </g>
                <g id="a2-p2">
                  <g className="cls-14">
                    <path id="a2-p2-stroke" ref={(element) => addToRefsArray(firstNameRefs, element)} className="cls-10" d="M250,0l3.1,13.94,4.36,23.72,5.7,30.83,4.72,25.69,5,26.47,6,30.9s7.32,40.36,9.57,53.85c.1.56.31,1.68.35,2.25.16,2,.57,4.48.57,6.51" transform="translate(5.72 16.04)"/>
                  </g>
                </g>
                <g id="a2-p3">
                  <g className="cls-12">
                    <line id="a2-p3-stroke" ref={(element) => addToRefsArray(firstNameRefs, element)} className="cls-7" x1="218.31" y1="188.16" x2="250.41" y2="187.98"/>
                  </g>
                </g>
                <g id="a2-p4">
                  <g className="cls-13">
                    <line id="a2-p4-stroke" ref={(element) => addToRefsArray(firstNameRefs, element)} className="cls-7" x1="281.28" y1="188.87" x2="248.9" y2="188.87"/>
                  </g>
                </g>
              </g>
              <g id="k1">
                <g id="k1-p1">
                  <g className="cls-15">
                    <line id="k1-p1-stroke" ref={(element) => addToRefsArray(firstNameRefs, element)} className="cls-16" x1="332.82" y1="15.59" x2="332.82" y2="231.55"/>
                  </g>
                </g>
                <g id="k1-p2">
                  <g className="cls-19">
                    <path id="k1-p2-stroke" ref={(element) => addToRefsArray(firstNameRefs, element)} className="cls-16" d="M405.24-1.29l-68,116s-.13.77-.18,1-.26,1-.33,1.27a8.76,8.76,0,0,1-.24,1.26,1.85,1.85,0,0,1-.42.61,1.37,1.37,0,0,1-.47.19,7.12,7.12,0,0,1-.94,0" transform="translate(5.72 16.04)"/>
                  </g>
                </g>
                <g id="k1-p3">
                  <g className="cls-17">
                    <line id="k1-p3-stroke" ref={(element) => addToRefsArray(firstNameRefs, element)} className="cls-18" x1="351.08" y1="116.39" x2="407.96" y2="231.83"/>
                  </g>
                </g>
              </g>
            </mask>
          </defs>
          <rect className='jaak-background' />
        </svg>
      </div>
      <div className='last-name-wrapper' ref={lastNameWrapperRef}>
        <svg
          id='Layer_1'
          data-name='Layer 1'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 759.05 249.56'
        >
          <defs>
            <clipPath id="clip-path-13" transform="translate(0.86 11.72)">
              <path id="k2-p1-clip" className="cls-20" d="M17.22,111.74V6.56c0-6.33,0-6.33-6.45-6.33H0A5.5,5.5,0,0,0,.14,1.59C2.48,6.3,2.65,11.35,2.65,16.46q0,97.25,0,194.49v3.47H17.22V211.1c0-27.83.22-55.66-.16-83.49Z"/>
            </clipPath>
            <clipPath id="clip-path-14" transform="translate(0.86 11.72)">
              <path id="k2-p2-clip" className="cls-20" d="M23.28,111.55c.67,1.22,1.13,2,1.52,2.75,10.26,20.37,20.73,40.64,30.69,61.15,6.22,12.81,14.22,24.84,17.89,39h19.9c-1.21-1.65-2.15-2.79-2.94-4a120.28,120.28,0,0,1-6.46-10.38Q58.44,149.68,33.14,99.23a4.6,4.6,0,0,1,.2-5l-.42-.36-10,17.46Z"/>
            </clipPath>
            <clipPath id="clip-path-15" transform="translate(0.86 11.72)">
              <path id="k2-p3-clip" className="cls-20" d="M27.46,104.35l-4.18,7.2c-2.9,5.42-6.31,9.64-6.22,16.06h-.47l.14-15.89.49,0,.46.19c.52-.79,1.06-1.56,1.53-2.37l4.37-7.48.15-.25,3.87,2.27Z"/>
            </clipPath>
            <clipPath id="clip-path-16" transform="translate(0.86 11.72)">
              <path id="k2-p4-clip" className="cls-20" d="M23.58,102.08Q52.44,52.64,81.28,3.2C83.22-.14,83.39-.2,87.84.56c-.44.86-.8,1.65-1.23,2.4Q60,48.59,33.34,94.2l-5.88,10.15-.14.22-3.88-2.25Z"/>
            </clipPath>
            <clipPath id="clip-path-17" transform="translate(0.86 11.72)">
              <path id="i1-clip" className="cls-20" d="M132.24,214.37H111.6c4.45-8.91,2.88-18.2,2.9-27.27q.18-81.72,0-163.45c0-7.68,1-15.53-3-23.13h20.26c-3,6.28-2.5,9.43-2.54,13.41-.22,20.49-.11,41-.11,61.48q0,60.74,0,121.46C129.15,202.78,129,208.7,132.24,214.37Z"/>
            </clipPath>
            <clipPath id="clip-path-18" transform="translate(0.86 11.72)">
              <path id="v1-p1-clip" className="cls-20" d="M202.17,214.05a11.27,11.27,0,0,1-5.89,1.77c-5.33.26-9.68-2.57-10.85-7.69-1.57-6.89-2.77-13.87-4.05-20.83q-4.31-23.33-8.54-46.67-5.37-29.23-10.76-58.45-5.73-31.07-11.48-62.13c-.87-4.65-1.85-9.29-3-13.88A36.69,36.69,0,0,0,145.06.48h19.63c-.14,4.2-.71,8.26-.3,12.22.56,5.36,1.86,10.65,2.84,16q5,27.13,10,54.28,4.57,24.8,9.17,49.6,4.69,25.41,9.37,50.83,2.11,11.55,4.27,23.08a3.93,3.93,0,0,0,1.15,2.22l.26-1.42,1.23,6.4Z"/>
            </clipPath>
            <clipPath id="clip-path-19" transform="translate(0.86 11.72)">
              <path id="v1-p2-clip" className="cls-20" d="M201.2,208.68q1.73-9.54,3.43-19.06Q209.3,163.31,214,137t9.29-52.63q4.59-26,9.22-51.88Q235,18,237.6,3.47c.61-3.51.84-3.69,5-2.83-.87,5.05-1.69,10-2.56,14.87q-4.31,24.35-8.64,48.69-4.66,26.31-9.3,52.63t-9.31,52.87c-2.22,12.54-4.43,25.09-6.72,37.61a9.89,9.89,0,0,1-3.94,6.74Z"/>
            </clipPath>
            <clipPath id="clip-path-20" transform="translate(0.86 11.72)">
              <path id="i2-clip" className="cls-20" d="M281.82,214.37H261.18c4.45-8.91,2.88-18.2,2.9-27.27q.18-81.72,0-163.45c0-7.68,1.05-15.53-3-23.13h20.26c-3,6.28-2.5,9.43-2.54,13.41-.22,20.49-.11,41-.11,61.48q0,60.74,0,121.46C278.73,202.78,278.55,208.7,281.82,214.37Z"/>
            </clipPath>
            <clipPath id="clip-path-21" transform="translate(0.86 11.72)">
              <path id="n1-p3-clip" className="cls-20" d="M395.62,214.13c2.39-1.55,3.48-4.25,3.52-8.51,0-.83,0-1.66,0-2.5V35.64q0-16.37,0-32.74c0-2.54-.56-3-2.9-2.65s-1.33,2-1.33,3.14q-.06,101.23,0,202.48V209l-.7.11-.38.1,1.5,5.15Z"/>
            </clipPath>
            <clipPath id="clip-path-22" transform="translate(0.86 11.72)">
              <path id="n1-p2-clip" className="cls-20" d="M394.17,209.07c-.26-.71-.58-1.41-.79-2.13Q383.62,173,373.88,139L345.44,40c-2.92-10.16-5.79-20.33-8.79-30.46-1.19-4-3.39-7.77-7.63-8.53a60.27,60.27,0,0,0-9-.76l-.63,0v6.4H320l.56-.17c.25.69.55,1.37.75,2.08q10.14,35.4,20.25,70.8Q358.25,137.59,375,195.89a117.31,117.31,0,0,0,4.63,14.21c2.18,5,7.18,6.72,13.15,5.21a9.22,9.22,0,0,0,2.85-1.18Z"/>
            </clipPath>
            <clipPath id="clip-path-23" transform="translate(0.86 11.72)">
              <path id="n1-p1-clip" className="cls-20" d="M320,6.57v207.8h-4.23V211q0-96.85,0-193.72c0-5.45-.1-10.84-2.58-15.86-.1-.2,0-.48,0-1.37,2.26.12,4.53.14,6.79.2Z"/>
            </clipPath>
            <clipPath id="clip-path-24" transform="translate(0.86 11.72)">
              <path id="u1-p2-clip" className="cls-20" d="M473.86,216.8a61.05,61.05,0,0,0,12.23-1c14.08-2.53,23.77-10.65,28.63-24.24,2.3-6.44,2.83-13.16,2.83-19.93q0-55.36,0-110.73,0-28.86,0-57.73c0-2-.29-3.2-2.7-3s-1.65,2-1.65,3.18q-.06,43.88,0,87.73,0,40.37,0,80.73c0,2.58-.05,5.17-.26,7.74-1.42,17.58-12.51,30.31-29.58,32.59a34.83,34.83,0,0,1-9.57-.05l-.58-.09,0,4.74Z"/>
            </clipPath>
            <clipPath id="clip-path-25" transform="translate(0.86 11.72)">
              <path id="u1-p1-clip" className="cls-20" d="M473.86,216.8a56.25,56.25,0,0,1-19.93-4.07c-13.92-5.59-21.22-16.32-23.27-30.86a80.84,80.84,0,0,1-.64-11.22Q430,87.29,430,3.94c0-3.7,0-3.71,3.63-3.71,2.92,0,5.84.09,8.75,0,1.9-.07,2.57.7,2.39,2.5a21.1,21.1,0,0,0,0,2.25q0,82.73,0,165.46c0,8.42,1,16.58,4.84,24.25a31.92,31.92,0,0,0,24.21,17.47Z"/>
            </clipPath>
            <clipPath id="clip-path-26" transform="translate(0.86 11.72)">
              <path id="k3-p1-clip" className="cls-20" d="M565.62,111.71V6.53c0-6.33,0-6.33-6.45-6.33H548.4a5.5,5.5,0,0,0,.14,1.36c2.34,4.71,2.51,9.76,2.51,14.87q0,97.25,0,194.49v3.47h14.59v-3.32c0-27.83.22-55.66-.16-83.49Z"/>
            </clipPath>
            <clipPath id="clip-path-27" transform="translate(0.86 11.72)">
              <path id="k3-p2-clip" className="cls-20" d="M571.68,111.52c.67,1.22,1.13,2,1.52,2.75,10.26,20.37,20.73,40.64,30.69,61.15,6.22,12.81,14.22,24.84,17.89,39h19.9c-1.21-1.65-2.15-2.79-2.94-4A116.31,116.31,0,0,1,632.29,200Q606.84,149.65,581.54,99.2a4.6,4.6,0,0,1,.2-5l-.42-.36-10,17.46Z"/>
            </clipPath>
            <clipPath id="clip-path-28" transform="translate(0.86 11.72)">
              <path id="k3-p3-clip" className="cls-20" d="M575.86,104.32l-4.18,7.2c-2.9,5.42-6.31,9.65-6.22,16.06H565l.14-15.89.49,0,.46.19c.52-.79,1.06-1.56,1.54-2.37l4.36-7.48.15-.25,3.87,2.27Z"/>
            </clipPath>
            <clipPath id="clip-path-29" transform="translate(0.86 11.72)">
              <path id="k3-p4-clip" className="cls-20" d="M572,102.05q28.86-49.44,57.7-98.88c1.94-3.33,2.11-3.4,6.56-2.64-.44.86-.8,1.65-1.23,2.4Q608.4,48.56,581.74,94.17l-5.88,10.15-.14.22-3.88-2.25Z"/>
            </clipPath>
            <clipPath id="clip-path-30" transform="translate(0.86 11.72)">
              <path id="k4-p1-clip" className="cls-20" d="M677.7,111.71V6.53c0-6.33,0-6.33-6.46-6.33H660.48a4.65,4.65,0,0,0,.14,1.36c2.33,4.71,2.51,9.76,2.5,14.87q0,97.25,0,194.49v3.47H677.7v-3.32c0-27.83.21-55.66-.16-83.49Z"/>
            </clipPath>
            <clipPath id="clip-path-31" transform="translate(0.86 11.72)">
              <path id="k4-p2-clip" className="cls-20" d="M683.75,111.52c.68,1.22,1.13,2,1.53,2.75C695.54,134.64,706,154.91,716,175.42c6.22,12.81,14.22,24.84,17.89,39h19.89c-1.2-1.65-2.14-2.79-2.94-4A121.1,121.1,0,0,1,744.36,200Q718.92,149.65,693.61,99.2a4.63,4.63,0,0,1,.2-5l-.41-.36-10,17.46Z"/>
            </clipPath>
            <clipPath id="clip-path-32" transform="translate(0.86 11.72)">
              <path id="k4-p3-clip" className="cls-20" d="M687.93,104.32l-4.18,7.2c-2.89,5.42-6.3,9.65-6.21,16.06h-.47l.14-15.89.49,0,.46.19c.51-.79,1.06-1.56,1.53-2.37l4.37-7.48.14-.25,3.88,2.27Z"/>
            </clipPath>
            <clipPath id="clip-path-33" transform="translate(0.86 11.72)">
              <path id="k4-p4-clip" className="cls-20" d="M684.06,102.05,741.75,3.17c2-3.33,2.12-3.4,6.57-2.64-.44.86-.8,1.65-1.24,2.4q-26.61,45.63-53.27,91.24l-5.88,10.15-.13.22-3.88-2.25Z"/>
            </clipPath>
            <linearGradient id='kivinukk-grad' x1='50%' y1='100%' x2='50%' y2='0%'>
              <stop
                offset='0%'
                style={{ stopColor: 'rgba(255,255,255,0.2)', stopOpacity: '1' }}
              />
              <stop
                offset='5%'
                style={{ stopColor: 'rgba(255,255,255,0.2)', stopOpacity: '1' }}
              />
              <stop
                offset='50%'
                style={{ stopColor: 'rgba(255,255,255,0.8)', stopOpacity: '1' }}
              />
            </linearGradient>
            <linearGradient id='kivinukk-grad' x1='50%' y1='100%' x2='50%' y2='0%'>
              <stop
                offset='0%'
                style={{ stopColor: 'rgba(0, 0, 0, 0.2)', stopOpacity: '1' }}
              />
              <stop
                offset='5%'
                style={{ stopColor: 'rgba(0, 0, 0, 0.2)', stopOpacity: '1' }}
              />
              <stop
                offset='50%'
                style={{ stopColor: 'rgba(0, 0, 0, 0.8)', stopOpacity: '1' }}
              /> 
            </linearGradient>
            <mask id='kivinukk-mask'>
              <g id="k2">
                <g id="k2-p1">
                  <g className="cls-21">
                    <line id="k2-p1-stroke" ref={(element) => addToRefsArray(lastNameRefs, element)} className="cls-22" x1="9.5" y1="10.53" x2="9.5" y2="227.55"/>
                  </g>
                </g>
                <g id="k2-p2">
                  <g className="cls-23">
                    <line id="k2-p2-stroke" ref={(element) => addToRefsArray(lastNameRefs, element)} className="cls-24" x1="85.02" y1="226.77" x2="28.43" y2="112.17"/>
                  </g>
                </g>
                <g id="k2-p3">
                  <g className="cls-25">
                    <polyline id="k2-p3-stroke" ref={(element) => addToRefsArray(lastNameRefs, element)} className="cls-26" points="27.3 114.43 19.8 127.05 19.56 127.71 19.56 128.36 19.56 129.44 19.28 130.1 18.95 130.8 18.48 131.27 18.06 131.55 17.17 131.55"/>
                  </g>
                </g>
                <g id="k2-p4">
                  <g className="cls-27">
                    <line id="k2-p4-stroke" ref={(element) => addToRefsArray(lastNameRefs, element)} className="cls-28" x1="25.99" y1="115.51" x2="86.81" y2="11.52"/>
                  </g>
                </g>
              </g>
              <g id="i1">
                <g className="cls-29">
                  <line id="i1-stroke" ref={(element) => addToRefsArray(lastNameRefs, element)} className="cls-30" x1="122.76" y1="229.87" x2="122.76" y2="9.87"/>
                </g>
              </g>
              <g id="v1">
                <g id="v1-p1">
                  <g className="cls-31">
                    <line id="v1-p1-stroke" ref={(element) => addToRefsArray(lastNameRefs, element)} className="cls-32" x1="197.6" y1="229.3" x2="156.68" y2="11"/>
                  </g>
                </g>
                <g id="v1-p2">
                  <g className="cls-33">
                    <line id="v1-p2-stroke" ref={(element) => addToRefsArray(lastNameRefs, element)} className="cls-34" x1="202.93" y1="227.33" x2="241.77" y2="11.15"/>
                  </g>
                </g>
              </g>
              <g id="i2">
                <g className="cls-35">
                  <line id="i2-stroke" ref={(element) => addToRefsArray(lastNameRefs, element)} className="cls-30" x1="272.33" y1="229.87" x2="272.33" y2="9.87"/>
                </g>
              </g>
              <g id="n1">
                <g id="n1-p1">
                  <g className="cls-39">
                    <line id="n1-p1-stroke" ref={(element) => addToRefsArray(lastNameRefs, element)} className="cls-40" x1="317.42" y1="226.58" x2="317.42" y2="11"/>
                  </g>
                </g>
                <g id="n1-p2">
                  <g className="cls-37">
                    <line id="n1-p2-stroke" ref={(element) => addToRefsArray(lastNameRefs, element)} className="cls-38" x1="389.53" y1="228.18" x2="326.84" y2="11.9"/>
                  </g>
                </g>
                <g id="n1-p3">
                  <g className="cls-36">
                    <line id="n1-p3-stroke" ref={(element) => addToRefsArray(lastNameRefs, element)} className="cls-28" x1="397.33" y1="226.77" x2="397.33" y2="11.09"/>
                  </g>
                </g>
              </g>
              <g id="u1">
                <g id="u1-p1">
                  <g className="cls-42">
                    <path id="u1-p1-stroke" ref={(element) => addToRefsArray(lastNameRefs, element)} className="cls-43" d="M474.41,214.42s-2,0-2.68,0-2.12-.21-2.81-.35-2.44-.68-3.24-.92c-1-.29-2.92-.85-3.87-1.2s-3-1.22-3.94-1.68-3.05-1.58-4-2.19-2.39-1.66-3.16-2.25-2.54-2-3.31-2.74a35.28,35.28,0,0,1-2.82-3.17c-.52-.71-1.4-2.25-1.83-3s-1.45-2.76-1.83-3.73-1-3.15-1.27-4.22-.69-3.43-.91-4.58-.63-3-.78-4-.42-4.08-.42-4.08l-.14-19.85-.07-157" transform="translate(0.86 11.72)"/>
                  </g>
                </g>
                <g id="u1-p2">
                  <g className="cls-41">
                    <path id="u1-p2-stroke" ref={(element) => addToRefsArray(lastNameRefs, element)} className="cls-34" d="M472.58,214.11s2,.33,2.62.38c.47,0,1.41,0,1.88,0,.66,0,2,0,2.63,0s2.4-.1,3.19-.19c1-.11,2.82-.46,3.75-.66s3-.72,4-1a39.29,39.29,0,0,0,3.94-1.41c.83-.37,2.41-1.31,3.19-1.78s2.37-1.41,3.1-2,1.77-1.61,2.35-2.16,1.87-1.72,2.44-2.35,1.86-2.3,2.44-3.09,1.83-2.56,2.34-3.47c.42-.75,1.09-2.32,1.41-3.1s1-2.45,1.22-3.29.72-2.81.94-3.75.6-2.67.75-3.57c.13-.74.38-3,.38-3L515.37-.62" transform="translate(0.86 11.72)"/>
                  </g>
                </g>
              </g>
              <g id="k3">
                <g id="k3-p1">
                  <g className="cls-44">
                    <line id="k3-p1-stroke" ref={(element) => addToRefsArray(lastNameRefs, element)} className="cls-22" x1="557.9" y1="10.5" x2="557.9" y2="227.52"/>
                  </g>
                </g>
                <g id="k3-p2">
                  <g className="cls-45">
                    <line id="k3-p2-stroke" ref={(element) => addToRefsArray(lastNameRefs, element)} className="cls-24" x1="633.42" y1="226.74" x2="576.83" y2="112.14"/>
                  </g>
                </g>
                <g id="k3-p3">
                  <g className="cls-46">
                    <polyline id="k3-p3-stroke" ref={(element) => addToRefsArray(lastNameRefs, element)} className="cls-26" points="575.71 114.4 568.2 127.02 567.96 127.68 567.96 128.34 567.96 129.41 567.68 130.07 567.35 130.78 566.88 131.24 566.46 131.53 565.57 131.53"/>
                  </g>
                </g>
                <g id="k3-p4">
                  <g className="cls-47">
                    <line id="k3-p4-stroke" ref={(element) => addToRefsArray(lastNameRefs, element)} className="cls-28" x1="574.39" y1="115.48" x2="635.21" y2="11.49"/>
                  </g>
                </g>
              </g>
              <g id="k4">
                <g id="k4-p1">
                  <g className="cls-48">
                    <line id="k4-p1-stroke" ref={(element) => addToRefsArray(lastNameRefs, element)} className="cls-22" x1="669.98" y1="10.5" x2="669.98" y2="227.52"/>
                  </g>
                </g>
                <g id="k4-p2">
                  <g className="cls-49">
                    <line id="k4-p2-stroke" ref={(element) => addToRefsArray(lastNameRefs, element)} className="cls-24" x1="745.5" y1="226.74" x2="688.91" y2="112.14"/>
                  </g>
                </g>
                <g id="k4-p3">
                  <g className="cls-50">
                    <polyline id="k4-p3-stroke" ref={(element) => addToRefsArray(lastNameRefs, element)} className="cls-26" points="687.78 114.4 680.27 127.02 680.04 127.68 680.04 128.34 680.04 129.41 679.75 130.07 679.43 130.78 678.96 131.24 678.53 131.53 677.64 131.53"/>
                  </g>
                </g>
                <g id="k4-p4">
                  <g className="cls-51">
                    <line id="k4-p4-stroke" ref={(element) => addToRefsArray(lastNameRefs, element)} className="cls-28" x1="686.47" y1="115.48" x2="747.28" y2="11.49"/>
                  </g>
                </g>
              </g>
            </mask>
          </defs>
          <rect className='kivinukk-background' />
        </svg>
      </div>
    </div>

  return (
    <section className="home-section" ref={homeSectionRef}>
      <section className="viewport-height-catcher" ref={viewportHeightCatcherRef}></section>
      
      <div className="content-wrapper">
        <div className="info" ref={infoRef}>
          <div className="descriptive-text greeting" ref={greetingRef}><span ref={greetingInnerRef}>Hi, I am</span></div>
          {nameSvg}
          <div className="descriptive-text profession" ref={professionRef}><span ref={professionInnerRef}>Full-Stack developer</span></div>
          
          <div className="call-to-action-wrapper" ref={callToActionRef}>
            <button className="call-to-action-button portfolio-button" onClick={() => setComponent({ path: '/portfolio' })} >
              <span className="call-to-action-button-label">See My Work</span>
              <div className="call-to-action-button-background"></div>
            </button>

            <button className="call-to-action-button contact-button" onClick={handleLeaveAMessageClick}>
              <span className="call-to-action-button-label">Leave a Message</span>
              <div className="call-to-action-button-background"></div>
            </button>
          </div>
        </div>

        <div className="portrait-background" ref={portraitBackgroundRef}></div>
        <div className="portrait-cover" ref={portraitCoverRef}></div>

        <div className="portrait-wrapper" ref={portraitWrapperRef}>
          <div className="portrait1-wrapper" ref={portrait1WrapperRef}>
            <div className="portrait1-background"></div>
            <div className="portrait1" ref={portrait1Ref}>Web-<br/>sites<br/>promote<br/>you 24/7<br/>No emp-<br/>loyee<br/>will do<br/>that</div>
          </div>
          <div className="portrait2" ref={portrait2Ref}></div>
        </div>
      </div>
    </section>
  )
}

export default Home