import React, { useEffect, useState, useRef } from 'react';
import { Route, Switch, useLocation, useHistory } from 'react-router-dom'
import LocomotiveScroll from "locomotive-scroll";

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import Navigation from './components/Navigation'
import Transition from './components/Transition'
import ScrollDown from './components/ScrollDown'
import Alert from './components/Alert'
import Home from './components/Home'
import Portfolio from './components/Portfolio'
import About from './components/About'
import Contact from './components/Contact'

import './css/App.css';

gsap.registerPlugin(ScrollTrigger);

const App = () => {
  const history = useHistory()
  const location = useLocation()

  const [navbar2Refs, setNavbar2Refs] = useState([])

  const [component, setComponent] = useState({ path: location.pathname })
  const [scrollToContactForm, setScrollToContactForm] = useState(false)
  const [transition, setTransition] = useState({ active: true, shrinking: true, historySteps: null })

  const [locoScroll, setLocoScroll] = useState(null)

  const [alert, setAlert] = useState({
    message: null,
    alertType: null
  })

  const viewportHeightCatcherRef = useRef(null)
  const locomotiveScrollContainerRef = useRef(null)
  
  const { active } = transition
  const { message, alertType } = alert

  useEffect(() => {
    if (locoScroll === null) {
      if (window.visualViewport.width > 700) {
        const locomotiveScroll = new LocomotiveScroll({
          el: locomotiveScrollContainerRef.current,
          smooth: true,
          smoothMobile: true,
          lerp: 0.05
        })
    
        locomotiveScroll.on('scroll', ScrollTrigger.update)
    
        ScrollTrigger.defaults({
          scroller: locomotiveScrollContainerRef.current
        });
  
        ScrollTrigger.config({
          autoRefreshEvents: "visibilitychange,DOMContentLoaded,load"
        });
    
        ScrollTrigger.scrollerProxy(locomotiveScrollContainerRef.current, {
          scrollTop(value) {
            return arguments.length ?
              locomotiveScroll.scrollTo(value, 0, 0) :
              locomotiveScroll.scroll.instance.scroll.y
          },
          getBoundingClientRect() {
            return { top: 0, left: 0, width: window.visualViewport.width - 80, height: window.visualViewport.height - 80 };
          },
          pinType: locomotiveScrollContainerRef.current.style.transform ? 'transform' : 'fixed'
        })
    
        ScrollTrigger.addEventListener('refresh', () => locomotiveScroll.update())
        ScrollTrigger.refresh()
    
        setLocoScroll(locomotiveScroll)
      } else {
        ScrollTrigger.config({
          autoRefreshEvents: "visibilitychange,DOMContentLoaded,load"
        });
    
        setLocoScroll(true)
      }
    }
  }, [locoScroll])

  useEffect(() => {
    if (window.history.state === null) {
      window.history.replaceState({ state: -2 }, null, document.URL);
      window.history.pushState({ state: 0 }, null, document.URL);
    } else {
      window.history.go(1)
    }
    
    history.listen(() => {
      if (history.action === 'PUSH') {
        window.history.replaceState({ state: -1 }, null, document.URL)
        window.history.pushState({ state: 0 }, null, document.URL)
        setTransition({ active: true, shrinking: true, historySteps: null })
      }
    })
  }, [history])

  useEffect(() => {
    const onPopstate = event => {
      if (event.state) {
        if (active) {
          if (event.state.state < 0) {
            window.history.go(1)
          } else if (event.state.state === 1 && event.state.pageLink === undefined) {
            window.history.go(-1)
          }
        } else {
          if (event.state.state === 0) {
            setTransition({ active: true, shrinking: true, historySteps: null })
          } else {
            setTransition({ active: true, shrinking: false, historySteps: event.state.state })
          }
        }
      }
    }

    window.addEventListener('popstate', onPopstate)
    return () => window.removeEventListener('popstate', onPopstate)
  }, [active])

  useEffect(() => {
    if (location.pathname !== component.path)
      setTransition({ active: true, shrinking: false, historySteps: 5 })
  }, [component, location.pathname])

  useEffect(() => {
    if (locoScroll?.scroll) {
      // locoScroll.destroy()
      // locoScroll.init()
      // locoScroll.scroll.windowHeight = window.visualViewport.height
      locoScroll.scroll.windowHeight = viewportHeightCatcherRef.current.getBoundingClientRect().height
      locoScroll.update()
      // locoScroll.on('scroll', ScrollTrigger.update)
      // ScrollTrigger.refresh(true)
    }
  }, [locoScroll, location.pathname])

  return (
    <>
      <section className="viewport-height-catcher" ref={viewportHeightCatcherRef}></section>
      <Navigation locoScroll={locoScroll} transition={transition} setComponent={setComponent} setNavbar2Refs={setNavbar2Refs} navbar2Refs={navbar2Refs}/>
      <Transition setTransition={setTransition} transition={transition} componentPath={component.path}/>
      <ScrollDown transition={transition} targetRef={locomotiveScrollContainerRef.current}/>
      <Alert setAlert={setAlert} message={message} type={alertType}/>
      <div className="locomotive-scroll" ref={locomotiveScrollContainerRef}>
        <Switch>
          <Route exact path='/' render={(props) => (
              <Home {...props} locoScroll={locoScroll} transition={transition} setComponent={setComponent} setScrollToContactForm={setScrollToContactForm} setNavbar2Refs={setNavbar2Refs}/>
            )}/>
          <Route exact path='/portfolio' render={(props) =>
            <Portfolio {...props} locoScroll={locoScroll} setNavbar2Refs={setNavbar2Refs}/>
          }/>
          <Route exact path='/about'
            render={(props) => (
              <About {...props} locoScroll={locoScroll} transition={transition} setNavbar2Refs={setNavbar2Refs}/>
            )}/>
          <Route exact path='/contact'
            render={(props) => (
              <Contact {...props} locoScroll={locoScroll} transition={transition} setNavbar2Refs={setNavbar2Refs} setScrollToContactForm={setScrollToContactForm} setAlert={setAlert} scrollToContactForm={scrollToContactForm}/>
            )}/>
        </Switch>
      </div>
    </>
  )
}
  
export default App;
